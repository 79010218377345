import React from "react";
import Login from "./components/Login/Login";
// import Auth from "./components/Login/Auth";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AlertTemplate from "../src/components/_common/Alert/AlertTemplate";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LayoutComp from "./components/LayoutComp/LayoutComp";
import NotFound from "./components/_common/PagenotFound/PagenotFound";
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "10px",
  transition: transitions.SCALE,
};
const App = () => {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Routes>
          <Route path="/*" element={<LayoutComp />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AlertProvider>
  );
};

export default App;
