import React from "react";
import styles from "./Pill.module.scss";

function Pill(props) {
  return (
    <div style={{ backgroundColor: props.color }} className={styles.pill}>
      {props.children}
    </div>
  );
}

export default Pill;
