import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
};

export const FinancialReducer = createSlice({
  name: "financial",
  initialState,
  reducers: {
    getFinancialData(state, action) {
      state.data = action.payload.data.data.payload.records;
      state.count = action.payload.data.data.payload.count;
    },
  },
});

export const { getFinancialData } = FinancialReducer.actions;

export default FinancialReducer.reducer;
