import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
  detail: {},
};

export const kycReducer = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    getKYCData(state, action) {
      state.data = action.payload.data.data.payload.records;
      state.count = action.payload.data.data.payload.count;
    },
    getKYCDetailData(state, action) {
      state.detail = action.payload.data.data.payload;
    },
  },
});

export const { getKYCData, getKYCDetailData } = kycReducer.actions;

export default kycReducer.reducer;
