import { URL } from "../utills/constants/url.constants";
import HTTP_CLIENT from "../utills/request";
export const getAssets = () => {
  return HTTP_CLIENT.get(URL.GET_ASSETS);
};
export const setIssuer = (data) => {
  return HTTP_CLIENT.post(URL.SET_ISSUER, data);
};
export const getIssuer = () => {
  return HTTP_CLIENT.get(URL.GET_ISSUER);
};
export const getDistributer = () => {
  return HTTP_CLIENT.get(URL.GET_DISTRIBUTER);
};

export const setDistributer = (data) => {
  return HTTP_CLIENT.post(URL.SET_DISTRIBUTER, data);
};
export const setConfigure = (data) => {
  return HTTP_CLIENT.post(URL.SET_CONFIGURE, data);
};
export const setIssueAssets = (data) => {
  return HTTP_CLIENT.post(URL.SET_ISSUEASSETS, data);
};
export const setTrust = (data) => {
  return HTTP_CLIENT.post(URL.SET_TRUST, data);
};
