import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Statistic } from "antd";
import {
  UsergroupAddOutlined,
  StockOutlined,
  MailOutlined,
  EditOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import styles from "./Dashboard.module.scss";
import { useAlert } from "react-alert";
import Graph from "../_common/Graph/graph";
import { Link } from "react-router-dom";
import { getStats } from "../../services/stats.services";
import { useDispatch, useSelector } from "react-redux";
import { getStatsData } from "../../store/reducers/statsReducer";
import LoadingIcon from "../_common/LoadingIcon/LoadingIcon";

const Dashboard = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.stats);
  const [loading, setLoading] = useState(false);

  // const loading = false;

  const getStatesDataHandler = async () => {
    try {
      setLoading(true);
      const response = await getStats();
      console.log(response);
      dispatch(getStatsData(response));
      setLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message && err.response.data.message);
        setLoading(false);
      } else {
        alert.error("Stats loading failed");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getStatesDataHandler();
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Dashboard</div>
        </div>
        <div className={styles.divider} />
        {loading ? (
          <LoadingIcon />
        ) : (
          <section>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={4}>
                <Card>
                  <div className={styles.cardinner}>
                    <div className={styles.cardiconmain}>
                      <UsergroupAddOutlined className={styles.icon} />
                    </div>
                    <h2 className={styles.count}>{data.accounts}</h2>
                    <h4>Account</h4>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={8} md={8} lg={4}>
                <Card>
                  <div className={styles.cardinner}>
                    <div className={styles.cardiconmain}>
                      <DollarCircleOutlined className={styles.icon} />
                    </div>
                    <h2 className={styles.count}>{data.coins}</h2>
                    <h4>Coins</h4>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={8} md={8} lg={4}>
                <Card style={{ justifyContent: "center", display: "flex" }}>
                  <div className={styles.cardinner}>
                    <div className={styles.cardiconmain}>
                      <MailOutlined className={styles.icon} />
                    </div>
                    <h2 className={styles.count}>{data.kycs}</h2>
                    <h4>KYC Requests</h4>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={8} md={8} lg={4}>
                <Card style={{ justifyContent: "center", display: "flex" }}>
                  <div className={styles.cardinner}>
                    <div className={styles.cardiconmain}>
                      <StockOutlined className={styles.icon} />
                    </div>
                    <h2 className={styles.count}>{data.trxs}</h2>
                    <h4>Transactions</h4>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={8} md={8} lg={4}>
                <Card style={{ justifyContent: "center", display: "flex" }}>
                  <div className={styles.cardinner}>
                    <div className={styles.cardiconmain}>
                      <EditOutlined className={styles.icon} />
                    </div>
                    <h2 className={styles.count}>{data.pendingTrxs}</h2>
                    <h4>Pending TRX</h4>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={8} md={8} lg={4}>
                <Card style={{ justifyContent: "center", display: "flex" }}>
                  <div className={styles.cardinner}>
                    <div className={styles.cardiconmain}>
                      <FileDoneOutlined className={styles.icon} />
                    </div>
                    <h2 className={styles.count}>{data.completedTrxs}</h2>
                    <h4>Completed TRX</h4>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.lowerrow}>
              <Col
                // xlg={18}
                lg={18}
                md={24}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <Card
                  style={{ flex: 1 }}
                  // bodyStyle={{ padding: "0" }}
                >
                  <div className={styles.transaction_card}>
                    <h3>Transactions</h3>
                    <Graph data={data.trxMap} />
                  </div>
                </Card>
              </Col>
              <Col
                // xlg={6}
                lg={6}
                md={24}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <Card className={styles.lower_left_card} style={{ flex: 1 }}>
                  <Row gutter={[16, 16]}>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <Statistic
                        title="Completed"
                        value={data.completedTrxs}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <Statistic
                        title="Overall"
                        value={data.trxs}
                        valueStyle={{ color: "#cf1322" }}
                      />
                    </Col>

                    {/* <Col lg={9} xs={24}>
                      <Statistic
                        title="Successful Transactions"
                        value={539}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Col> */}
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className={styles.pendingtitle}
                    >
                      <Statistic
                        title="Pending/Cancelled Transactions"
                        value={data.pendingTrxs}
                        valueStyle={{ color: "#cf1322" }}
                      />
                    </Col>
                    <Col lg={24} md={12} sm={24} xs={24}>
                      <Link to="/transactions">
                        <Button
                          type="primary"
                          block
                          className={styles.transcationbtn}
                        >
                          View Transactions
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </section>
        )}
      </div>
    </>
  );
};

export default Dashboard;
