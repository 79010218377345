import { Modal, Input, Row, Col, Tooltip, Button, Card } from "antd";
import React, { useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import BarcodeReader from "react-barcode-reader";
const ReceiveIssuerModal = ({ detail, visible, onCancel, Distributer }) => {
  const { TextArea } = Input;
  return (
    <Modal
      title="BTC Asset Distributor deposit address"
      visible={visible}
      onCancel={onCancel}
      footer=""
      centered
    >
      <div>
        <BarcodeReader
        // onError={this.handleError}
        // onScan={this.handleScan}
        />
      </div>
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24}>
            <Input.Group compact>
              <Input
                style={{ width: "calc(100% - 31px)" }}
                value={Distributer}
              />
              <Tooltip title="Copy Distributor">
                <Button
                  onClick={() => navigator.clipboard.writeText(Distributer)}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            </Input.Group>
          </Col>
          <Col xs={24} xl={24}>
            <Card>
              Note: This is BTC Distributor address. It will be used to
              Distribute and receive BTC asset when user make real BTC deposits
              or withdraw
            </Card>
          </Col>
          <Col xs={24} xl={24}>
            <Card>
              Warning! Do not send any coin to this address other than BTC and
              XLM otherwise your funds will be lost
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
export default ReceiveIssuerModal;
