import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./deposit.module.scss";
import { useAlert } from "react-alert";
import { Row, Col, Input } from "antd";
import { getTransction } from "../../services/transctionService";
import { getTransctionData } from "../../store/reducers/transctionReducer";
import { useDispatch, useSelector } from "react-redux";
import Pill from "../_common/Pill/Pill";

const DepositeAndWithdraw = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, count } = useSelector((state) => state.transaction);
  const getData = async (page) => {
    try {
      setLoading(true);
      const data = await getTransction(page);
      console.log("GHSFDJAFGSDJHAGbnfyughklhuydyt555555", data);
      dispatch(
        getTransctionData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.show("Transaction loading failed!", { type: "error" });
      setLoading(false);
    }
  };
  useEffect(() => {
    getData(1);
  }, []);
  const alert = useAlert();
  const columns = [
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      // render: (account) => {
      //   return <div className={styles.amount}>{account && account}</div>;
      // },
    },
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        return (
          <Pill color={type === "withdraw" ? "red" : "green"}>
            {type && type}
          </Pill>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
      render: (status) => {
        return (
          <Pill color={status === "completed" ? "green" : "gray"}>
            <SyncOutlined style={{ cursor: "pointer" }} />
            {status && status}
          </Pill>
        );
      },
    },
    {
      title: "Account In",
      key: "amountIn",
      dataIndex: "amountIn",
      render: (amountIn, type, amountOut) => {
        const Type = type.type;
        console.log("type", type.type);
        return (
          <div className={styles.amount}>
            {Type === "withdraw"
              ? amountOut
              : Type === "deposit"
              ? amountIn && amountIn
              : Type === "BisonArmyGiven"
              ? amountIn && amountIn
              : null}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Deposit And Withdraw</div>
        </div>
        <div className={styles.divider} />
        <div className={styles.midsection}>
          <Row>
            <Col lg={8} sm={24}>
              <Input prefix={<SearchOutlined />} />
            </Col>
          </Row>
        </div>
        <Table
          loading={loading}
          columns={columns}
          pagination={{
            total: count,
            onChange(e) {
              getData(e);
            },
          }}
          scroll={{ x: true }}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default DepositeAndWithdraw;
