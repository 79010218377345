import { isRejected } from "@reduxjs/toolkit";
import {
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Modal,
  Radio,
  Row,
  Spin,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getKYCSDetail, setKYCStatus } from "../../../services/kycs.service";
import { getKYCDetailData } from "../../../store/reducers/kycReducer";

function KYCDetailsModel({
  detail,
  visible,
  onCancel,
  setKYCStatusHandler,
  KYCStatusData,
  eventHandler,
  KYCStatusOkay,
  KYCStatusValue,
  textEventHandler,
  okButtonProps,
  textArea,
}) {
  // const KYCStatusValueHandler = (e) => {
  //   setKYCStatusValue(e === "a" ? "approved" : "rejected");
  // };

  return (
    <Modal
      title="KYC Details"
      visible={visible}
      onOk={setKYCStatusHandler}
      confirmLoading={KYCStatusOkay}
      onCancel={onCancel}
      width={1200}
      okButtonProps={{ disabled: okButtonProps }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Type:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.accountType}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Account:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
              }}
            >
              {detail && detail.xaccount}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>First Name:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.first_name}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Last Name:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.last_name}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Additional Name:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            ></Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Address:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.address}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Postcode:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
              }}
            >
              {detail && detail.postal_code}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>City:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.city}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>State or Province:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.state_or_province}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Country:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.id_country_code}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>ID Expiration Date:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.id_expiration_date}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>ID Issue Date:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
              }}
            >
              {detail && detail.id_issue_date}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>KYC Status:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
                textTransform: "capitalize ",
              }}
            >
              {detail && detail.status}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Date of Birth:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
              }}
            >
              {detail && detail.birth_date}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Mobile:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
              }}
            >
              {detail && detail.mobile_number}
            </Typography>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card size="small">
            <Typography>Email:</Typography>
            <Typography
              style={{
                fontSize: "12px",
                opacity: "60%",
                fontWeight: "lighter",
              }}
            >
              {detail && detail.email_address}
            </Typography>
          </Card>
        </Col>
        <Col sx={24} xl={24}>
          <Card title="Pictures:" size="small">
            <Row>
              <Col sx={12} md={12} xl={12}>
                <Image xl={6} src={detail && detail.photo_id_back} p />
              </Col>
              <Col sx={12} md={12} xl={12}>
                <Image xl={6} src={detail && detail.photo_id_front} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sx={24} xl={24}>
          <Card size="small">
            <Typography>Accept/Reject KYC:</Typography>

            <Radio.Group
              value={
                KYCStatusValue === "approved"
                  ? "a"
                  : KYCStatusValue === "rejected"
                  ? "b"
                  : null
              }
              onChange={(e) => {
                detail.status === "inprogress" && eventHandler(e.target.value);
                // KYCStatusValueHandler(e.target.value);
              }}
              style={{ margin: "10px 0 10px 0", display: "flex", gap: "10px" }}
            >
              <Radio.Button value="a">Approve Request</Radio.Button>
              <Radio.Button value="b">Reject Request</Radio.Button>
            </Radio.Group>
          </Card>

          {detail.status === "approved" || detail.status === "rejected" ? (
            <TextArea rows={4} value={detail.admin_notes} maxLength={6} />
          ) : (
            (KYCStatusValue === "approved" || KYCStatusValue === "rejected") &&
            detail.status === "inprogress" && (
              <TextArea
                value={textArea}
                rows={4}
                // value={detail.admin_notes}
                placeholder="Please type the reason of your decision "
                maxLength={100}
                onChange={(e) => {
                  textEventHandler(e.target.value);
                  // KYCStatusValueHandler(e.target.value);
                }}
              />
            )
          )}
        </Col>
      </Row>
    </Modal>
  );
}

export default KYCDetailsModel;
