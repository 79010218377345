import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { DeleteOutlined, EditOutlined, SwapOutlined } from "@ant-design/icons";
import styles from "./swape.module.scss";
import { useAlert } from "react-alert";
import { Row, Col, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SwapAccountModal from "../_common/Modal/SwapAccountModal";
import {
  getSwapAcountDetail,
  deleteSwapAccount,
  configureSwapaccount,
  IssueAssetsSwapAccount,
} from "../../services/swapaccountService";
import { getSwapAccountData } from "../../store/reducers/swapReducer";
const SwapAccount = () => {
  useEffect(() => {
    getSwapAccountAddressDetail();
  }, []);
  const [loading, setLoading] = useState(false);
  const [viewswapaccount, setViewSwapAccount] = useState(false);
  const [postOrPatch, setPostOrPatch] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const { swapaccountdata } = useSelector((state) => state.swap);
  const getSwapAccountAddressDetail = async () => {
    try {
      setLoading(true);
      const data = await getSwapAcountDetail();
      dispatch(
        getSwapAccountData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("Swap Account Address loading failed!");
      setLoading(false);
    }
  };
  const viewSwapAccount = () => {
    setViewSwapAccount(true);
  };
  const cancelViewSwapAccount = () => {
    setViewSwapAccount(false);
  };
  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      const data = await deleteSwapAccount();
      // dispatch(
      //   getSwapAccountData({
      //     data: data,
      //   })
      // );
      alert.error("Swap Account Address Deleted!");
      setLoading(false);
    } catch (error) {
      alert.error("Swap Account Address Deleting failed!");
      setLoading(false);
    }
  };
  const handleConfigure = async () => {
    try {
      setLoading(true);
      const data = await configureSwapaccount();
      console.log("data", data);
      setLoading(false);
    } catch (error) {
      alert.error("Swap Account Issue Assets failed!");
      setLoading(false);
    }
  };
  const handleAssets = async () => {
    try {
      setLoading(true);
      const data = await IssueAssetsSwapAccount();
      setLoading(false);
    } catch (error) {
      alert.error("Swap Account Configure failed!");
      setLoading(false);
    }
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Swap Account</div>
        </div>
        <div className={styles.divider} />
        <div className={styles.midsection}>
          <Row>
            {!swapaccountdata ? (
              <>
                <Col lg={8} sm={24}>
                  <Button
                    className={styles.addfeebtn}
                    onClick={() => {
                      viewSwapAccount();
                    }}
                    type="primary"
                    icon={<SwapOutlined />}
                  >
                    Swap Account
                  </Button>
                </Col>
              </>
            ) : (
              <>
                <Col lg={24} md={24} sm={24} xs={24} className={styles.cardss}>
                  <Card
                    extra={<DeleteOutlined onClick={handleDeleteAccount} />}
                    title="Swap Account Address"
                  >
                    <Row gutter={[16, 16]}>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        {/* <p>Swap Account Address</p> */}
                        <Input value={swapaccountdata} />
                      </Col>
                      <Col lg={8} md={12} sm={24} xs={24}>
                        <Button
                          className={styles.update_btn}
                          type="primary"
                          onClick={() => {
                            viewSwapAccount();
                            setPostOrPatch(true);
                          }}
                        >
                          Update Account
                        </Button>
                      </Col>
                      <Col lg={8} md={12} sm={24} xs={24}>
                        <Button
                          className={styles.sacount_btn}
                          type="primary"
                          onClick={() => handleConfigure()}
                        >
                          Configure Swaping Account
                        </Button>
                      </Col>
                      <Col lg={8} md={12} sm={24} xs={24}>
                        <Button
                          type="primary"
                          className={styles.sacount_btn}
                          onClick={() => handleAssets()}
                        >
                          Issue Assets to Swap Account
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </div>
        <SwapAccountModal
          visible={viewswapaccount}
          onCancel={cancelViewSwapAccount}
          postOrPatch={postOrPatch}
        />
      </div>
    </>
  );
};

export default SwapAccount;
