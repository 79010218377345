import React, { useState } from "react";
// import Auth from "./components/Login/Auth";
import Header from "../_common/Header/Header";
import Navigation from "../_common/Navigation/Navigation";
import { Layout } from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import logo from "../../assets/logo.svg";
import Icon from "../../assets/icon.svg";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Dashboard from "../Dashboard/Dashboard";
import MasterWallet from "../MasterWallet/MasterWallet";
import Assets from "../Assets/Assets";
import KYC from "../KYC/KYC";
import Accounts from "../Accounts/Accounts";
import Hooks from "../Hooks/Hooks";
import Users from "../Users/Users";
import DepositeAndWithdraw from "../Depositandwithdraw/DepositandWithdraw";
import Fee from "../Fee/Fee";
import SwapAccount from "../Swap/Swap";
import Staking from "../Staking/Staking";
import NotFound from "../_common/PagenotFound/PagenotFound";
import { useSelector } from "react-redux";
const LayoutComp = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const { isLoggedIn } = useSelector((state) => state.auth);
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout.Sider
        // breakpoint="md"
        // onBreakpoint={() => {
        //   setCollapsed(!collapsed);
        // }}
        width={220}
        style={{ minHeight: "100vh" }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        // collapsedWidth="0"
      >
        <div className="sidebarLogo">
          <div
            style={{
              marginRight: collapsed ? "0px" : "10px",
            }}
          >
            <img
              src={collapsed ? Icon : logo}
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <Navigation style={{ marginTop: "15px" }} />
      </Layout.Sider>
      <Layout className="siteLayout">
        <Header collapsed={collapsed} toggle={toggle} />
        <Layout.Content
          className="siteLayoutBackground"
          style={{
            margin: "24px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/master-wallet" element={<MasterWallet />} />
            <Route path="/assets" element={<Assets />} />
            <Route path="/fee" element={<Fee />} />
            <Route path="/kyc" element={<KYC />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/hooks" element={<Hooks />} />
            <Route path="/users" element={<Users />} />
            <Route path="/transactions" element={<DepositeAndWithdraw />} />
            <Route path="/swap-account" element={<SwapAccount />} />
            <Route path="/staking-info" element={<Staking />} />
            <Route path="*" element={<NotFound />} />
            {/* <Route path="*" element={<Navigate to="/users" />} /> */}
          </Routes>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComp;
