import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  IsbanUser: {
    userId: "",
    isBanned: true,
  },
  IsunBanUser: {
    userId: "",
    isBanned: true,
  },
};
export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUserBan(state, action) {
      state.IsbanUser = action.payload;
    },
    setUnBanUser(state, action) {
      state.IsunBanUser = action.payload;
    },
  },
});

export const { setUserBan, setUnBanUser } = adminSlice.actions;

export default adminSlice.reducer;
