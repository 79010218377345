import React, { useEffect, useState } from "react";
import { Table, Spin, Button, Row, Col, Input, Typography } from "antd";
import {
  LoadingOutlined,
  SearchOutlined,
  UserOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import styles from "./KYC.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {
  getKYCS,
  getKYCSDetail,
  setKYCStatus,
} from "../../services/kycs.service";
import { getKYCData, getKYCDetailData } from "../../store/reducers/kycReducer";
import KYCDetailsModel from "../_common/Modal/KYCDetailsModel";
import LoadingButton from "../_common/LoadingButton/LoadingButton";
import Pill from "../_common/Pill/Pill";

const KYC = () => {
  const [viewKYCModel, setViewKYCModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, count, detail } = useSelector((state) => state.kyc);
  const alert = useAlert();
  const [KYCStatusData, setKYCStatusData] = useState({
    xaccount: "",
    status: "",
    note: "",
  });
  const [KYCStatusOkay, setKYCStatusOkay] = useState(false);
  const [KYCStatusValue, setKYCStatusValue] = useState(null);
  const [okButtonProps, setOkButtonProps] = useState(true);
  const [reloadGetData, setReloadGetData] = useState(false);

  const getData = async (page) => {
    try {
      setLoading(true);
      const data = await getKYCS(page);
      dispatch(
        getKYCData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("KYC loading failed");
      setLoading(false);
    }
  };
  useEffect(() => {
    getData(1);
  }, [reloadGetData]);

  const getDetailData = async (id, loadingHandler) => {
    try {
      const data = await getKYCSDetail(id);
      dispatch(
        getKYCDetailData({
          data: data,
        })
      );
      loadingHandler();
      setViewKYCModel(true);
      setKYCStatusValue(data.data.payload.status);

      // setOnClickLoading(false);
    } catch (error) {
      if (error.response) {
        alert.error(error.response.data.message && error.response.data.message);
        loadingHandler();
      } else {
        alert.error("KYC Detail loading failed");
        loadingHandler();
      }

      // setOnClickLoading(false);
    }
  };

  const setKYCStatusHandler = async () => {
    try {
      setKYCStatusOkay(true);
      const data = await setKYCStatus(KYCStatusData);
      alert.show("Status Updated");
      setViewKYCModel(false);
      setKYCStatusOkay(false);
      setReloadGetData((state) => !state);
    } catch (error) {
      setViewKYCModel(false);
      alert.error("Something wrong! Status not updated");
      setKYCStatusOkay(false);
    }
  };

  const eventHandler = (e) => {
    e === "a"
      ? setKYCStatusData({
          xaccount: detail && detail.xaccount,
          status: "approved",
          note: "",
        })
      : setKYCStatusData({
          xaccount: detail.xaccount,
          status: "rejected",
          note: "",
        });

    e === "a" ? setKYCStatusValue("approved") : setKYCStatusValue("rejected");
  };

  const textEventHandler = (e) => {
    setKYCStatusData({ ...KYCStatusData, note: e });
    KYCStatusData.note.length > 0 && setOkButtonProps(false);
  };
  const viewKYCModelHangler = (id, loadingHandler) => {
    getDetailData(id, loadingHandler);
  };
  const openViewKYCModelHangler = (id) => {
    setViewKYCModel(false);
    setKYCStatusValue(null);
    setOkButtonProps(true);

    setKYCStatusData({
      xaccount: "",
      status: "",
      note: "",
    });
  };

  const columns = [
    {
      dataIndex: "photo",
      key: "photo",
      render: (photo) => {
        return (
          <>
            {photo && photo ? (
              <Button
                style={{
                  cursor: "default",
                }}
                type="primary"
                shape="circle"
                size={1}
              >
                <img src={photo} />
              </Button>
            ) : (
              <Button
                style={{
                  cursor: "default",
                }}
                icon={<UserOutlined />}
                type="primary"
                shape="circle"
                size={1}
              ></Button>
            )}
          </>
        );
      },
    },
    {
      title: "Accounts",
      dataIndex: "account",
      key: "account",
      // render: (accounts) => (
      //   <div className={styles.amount}>{accounts && accounts}</div>
      // ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => (
        <Typography style={{ textTransform: "capitalize " }}>
          {firstName}
        </Typography>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (lastName) => (
        <Typography style={{ textTransform: "capitalize " }}>
          {lastName}
        </Typography>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Typography style={{ textTransform: "capitalize " }}>{type}</Typography>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Pill
          color={
            status === "approved"
              ? "green"
              : status === "rejected"
              ? "red"
              : "#177ddc"
          }
        >{`${status}`}</Pill>
      ),
    },
    {
      title: "",
      key: "",
      dataIndex: "account",
      render: (account) => (
        <>
          <LoadingButton
            viewKYCModelHangler={viewKYCModelHangler}
            account={account}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>KYC Management</div>
        </div>
        <div className={styles.divider} />
        <div className={styles.midsection}>
          <Row>
            <Col lg={8} sm={24}>
              <Input prefix={<SearchOutlined />} />
            </Col>
          </Row>
        </div>
        <Table
          loading={loading}
          columns={columns}
          pagination={{
            total: count,
            onChange(e) {
              getData(e);
            },
          }}
          scroll={{ x: true }}
          dataSource={data}
        />
        <KYCDetailsModel
          onCancel={openViewKYCModelHangler}
          visible={viewKYCModel}
          detail={detail}
          setKYCStatusHandler={setKYCStatusHandler}
          KYCStatusData={KYCStatusData}
          eventHandler={eventHandler}
          KYCStatusOkay={KYCStatusOkay}
          KYCStatusValue={KYCStatusValue}
          textEventHandler={textEventHandler}
          textArea={KYCStatusData.note}
          okButtonProps={okButtonProps}
        />
      </div>
    </>
  );
};

export default KYC;
