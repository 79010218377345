import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
  coldStakeAccountAddress: "",
  hotstakeAccountAddress: "",
};

export const StakingReducer = createSlice({
  name: "staking",
  initialState,
  reducers: {
    getStakingData(state, action) {
      state.data = action.payload.data.data.payload;
      // state.count = action.payload.data.data.payload.count;
    },
    getcoldStakeAccountData(state, action) {
      state.coldStakeAccountAddress = action.payload.data.data.payload.address;
    },
    gethotStakeAccountData(state, action) {
      state.hotstakeAccountAddress = action.payload.data.data.payload.address;
    },
  },
});

export const {
  getStakingData,
  getcoldStakeAccountData,
  gethotStakeAccountData,
} = StakingReducer.actions;

export default StakingReducer.reducer;
