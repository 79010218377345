import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
};

export const TransactionReducer = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    getTransctionData(state, action) {
      state.data = action.payload.data.data.payload.records;
      state.count = action.payload.data.data.payload.count;
    },
  },
});

export const { getTransctionData } = TransactionReducer.actions;

export default TransactionReducer.reducer;
