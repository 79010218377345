import React, { useEffect, useState } from "react";
import { Table, Row, Col, Input, Typography, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./Staking.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getstaking } from "../../services/stakingService";
import { getStakingData } from "../../store/reducers/stakingReducer";
import Pill from "../_common/Pill/Pill";
import moment from "moment";
import AddColdStakeModal from "../_common/Modal/AddColdStakeModal";
import AddHotStakeModal from "../_common/Modal/AddHotStakeModal";
import RewardSetting from "../_common/Modal/RewardSettingModal";
const Staking = () => {
  const [loading, setLoading] = useState(false);
  const [coldstake, setColdStake] = useState(false);
  const [hotstake, setHotStake] = useState(false);
  const [rewardsetting, setRewardsetting] = useState(false);
  const dispatch = useDispatch();
  const { data, count } = useSelector((state) => state.staking);
  const alert = useAlert();

  const getStaking = async (page) => {
    try {
      setLoading(true);
      const data = await getstaking(page);
      dispatch(
        getStakingData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("Staking loading failed");
      setLoading(false);
    }
  };
  useEffect(() => {
    getStaking(1);
  }, []);

  const columns = [
    {
      title: "Accounts",
      dataIndex: "xaccount",
      key: "xaccount",
      // render: (accounts) => (
      //   <div className={styles.amount}>{accounts && accounts}</div>
      // ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type, isClaimed, isStaked, refunded) => {
        return (
          <div>
            {isClaimed.isClaimed === true
              ? "Claimed"
              : isClaimed.isStaked === true
              ? "Staked"
              : isClaimed.refunded === true
              ? "UnStaked"
              : null}
          </div>
        );
      },

      // (
      //   <Typography style={{ textTransform: "capitalize " }}>{type}</Typography>
      // ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Pill
          color={
            status === "approved"
              ? "green"
              : status === "rejected"
              ? "red"
              : "#177ddc"
          }
        >{`${status}`}</Pill>
      ),
    },
    {
      title: "Stacked At",
      dataIndex: "stackedAt",
      key: "stackedAt",
      render: (stackedAt) => {
        const d = stackedAt;
        const convertedDate = moment(d).format("DD-MM-YYYY");
        return (
          <>
            <div>{convertedDate}</div>
          </>
        );
      },
      // render: (stackedAt) => getFullDate(stackedAt),
    },
    {
      title: "Amount",
      dataIndex: "amountIn",
      key: "amountIn",
    },
    {
      title: "Assets",
      dataIndex: "assetCode",
      key: "assetCode",
    },
  ];
  const getFullDate = (date) => {
    const dateAndTime = date.split("T");
    return dateAndTime[0].split("-").reverse().join("-");
  };
  const handleColdStake = () => {
    setColdStake(true);
  };
  const cancelColdStake = () => {
    setColdStake(false);
  };
  const handleHotStake = () => {
    setHotStake(true);
  };
  const cancelHotStake = () => {
    setHotStake(false);
  };
  const handleRewardSetting = () => {
    setRewardsetting(true);
  };
  const cancelRewardSetting = () => {
    setRewardsetting(false);
  };

  return (
    <>
      <div className={styles.Staking_wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Staking Info</div>
        </div>
        <div className={styles.divider} />
        <div className={styles.midsection}>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col lg={7} sm={24} xs={24}>
              <Input prefix={<SearchOutlined />} />
            </Col>
            <Col className={styles.button_wrapper}>
              <Button
                type="primary"
                className={styles.cold_btn}
                onClick={() => handleRewardSetting()}
              >
                Reward Setting
              </Button>
              <Button
                type="primary"
                className={styles.cold_btn}
                onClick={() => handleColdStake()}
              >
                Add Staker Cold account
              </Button>
              <Button
                type="primary"
                className={styles.hot_btn}
                onClick={handleHotStake}
              >
                Add Staker Hot account
              </Button>
            </Col>
          </Row>
        </div>
        <Table
          loading={loading}
          columns={columns}
          pagination={{
            total: count,
            onChange(e) {
              getStaking(e);
            },
          }}
          scroll={{ x: true }}
          dataSource={data}
        />
      </div>
      <AddColdStakeModal visible={coldstake} onCancel={cancelColdStake} />
      <AddHotStakeModal visible={hotstake} onCancel={cancelHotStake} />
      <RewardSetting visible={rewardsetting} onCancel={cancelRewardSetting} />
    </>
  );
};

export default Staking;
