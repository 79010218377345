import axios from "axios";
import { URL } from "../utills/constants/url.constants";
import HTTP_CLIENT from "../utills/request";
export const getKYCS = (page) => {
  return HTTP_CLIENT.get(`${URL.GET_KYCS}?page=${page}&limit=10`);
};
export const getKYCSDetail = (id) => {
  return HTTP_CLIENT.get(`${URL.GET_KYCS_DETAILS}/${id}`);
};
export const setKYCStatus = (data) => {
  return HTTP_CLIENT.post(URL.SET_KYCS_STATUS, data);
};
