import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessions: [],
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessions(state, action) {
      state.sessions = action.payload;
    },
    setEditSessionAction(state, action) {
      const index = state.sessions.findIndex(
        (item) => item.id === action.payload.id
      );
      state.sessions[index] = action.payload;
    },

    setUpdatedSessions(state, action) {
      const newData = state?.sessions.filter(
        (item) => item.id !== action.payload
      );
      state.sessions = newData;
    },
    setNewSession(state, action) {
      const index = state.sessions.push();
      state.sessions[index] = action.payload;
    },
  },
});

export const {
  setSessions,
  setEditSessionAction,
  setUpdatedSessions,
  setNewSession,
} = sessionSlice.actions;
export default sessionSlice.reducer;
