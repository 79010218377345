import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gyms: [],
  featuredGymsName: "",
};

export const gymSlice = createSlice({
  name: "gym",
  initialState,
  reducers: {
    setGyms(state, action) {
      state.gyms = action.payload;
    },
    setFeaturedGymsName(state, action) {
      state.featuredGymsName = action.payload;
    },
    setEditGymAction(state, action) {
      const index = state.gyms.findIndex(
        (item) => item.id === action.payload.id
      );
      state.gyms[index] = action.payload;
    },

    setUpdatedGyms(state, action) {
      const newData = state?.gyms.filter((item) => item.id !== action.payload);
      state.gyms = newData;
    },
    setNewGym(state, action) {
      const index = state.gyms.push();
      state.gyms[index] = action.payload;
    },
  },
});

export const {
  setGyms,
  setFeaturedGymsName,
  setUpdatedGyms,
  setEditGymAction,
  setNewGym,
} = gymSlice.actions;
export default gymSlice.reducer;
