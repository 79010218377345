import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  referrals: {},
};

export const ReferralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    setReferrals(state, action) {
      state.referrals = action.payload;
    },
    editReferralAction(state, action) {
      const index = state.referrals.data.findIndex(
        (item) => item.id === action.payload.id
      );
      state.referrals.data[index] = action.payload;
    },
    setUpdatedReferrals(state, action) {
      const newData = state.referrals.data.filter(
        (item) => item.id !== action.payload
      );
      state.referrals.data = newData;
    },
    setNewReferral(state, action) {
      const index = state.referrals.data.push();
      state.referrals.data[index] = action.payload;
    },
  },
});

export const {
  setReferrals,
  editReferralAction,
  setUpdatedReferrals,
  setNewReferral,
} = ReferralSlice.actions;
export default ReferralSlice.reducer;
