import { SendOutlined } from "@ant-design/icons";
import { Modal, Input, Row, Col, Typography, Button } from "antd";
import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { getIssuer, setIssuer } from "../../../services/assetsService";
const AddIssuerModal = ({ detail, visible, onCancel, coin, key }) => {
  const [issuerSeed, setIssuerSeed] = useState("");
  const coinRef = useRef();
  const seedRef = useRef();

  const [loading, setLoading] = useState(false);
  const [genloading, setGenLoading] = useState(false);
  alert = useAlert();
  const { TextArea } = Input;
  const generateSeed = async () => {
    try {
      setGenLoading(true);
      const data = await getIssuer();
      setIssuerSeed(data.data.payload);
      setGenLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setGenLoading(false);
      } else {
        alert.error("Generating failed!");
        setGenLoading(false);
      }
    }
  };
  const setIssuerHandler = async () => {
    try {
      setLoading(true);
      const response = await setIssuer({
        wallet_seed: seedRef.current.props.value,
        coinSymbol: coinRef.current.props.value,
      });
      alert.show(response.data.payload);

      setLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setLoading(false);
      } else {
        alert.error("Adding issuer failed");
        setLoading(false);
      }
    }
  };
  const onCancelHandler = () => {
    onCancel();
    setIssuerSeed("");
  };
  return (
    <Modal
      key={key}
      title="Add Issuer"
      visible={visible}
      onCancel={!loading && onCancelHandler}
      footer={
        <Button
          icon={<SendOutlined />}
          loading={loading}
          onClick={setIssuerHandler}
          type="primary"
        >
          Add Issuer
        </Button>
      }
      centered
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Input ref={seedRef} value={issuerSeed} />
          </Col>
          <Col lg={24}>
            <Button loading={genloading} onClick={generateSeed}>
              Generate Issuer
            </Button>
          </Col>
          <Col lg={24}>
            <Typography>Add Coin symbol</Typography>
            <Input ref={coinRef} value={coin} />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
export default AddIssuerModal;
