import React from "react";
import ReactDOM from "react-dom";
import "./custom-antd.less";
import "./global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/index";
import "./font/VistolSans-Black.ttf";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
//
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
