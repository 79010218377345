import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  count: 0,
};

const AccountsSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    getAccountsData(state, actions) {
      state.data = actions.payload.data.payload.records;
      state.count = actions.payload.data.payload.count;
    },
  },
});

export const { getAccountsData } = AccountsSlice.actions;
export default AccountsSlice.reducer;
