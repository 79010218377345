import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers, compose } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ReduxThunk from "redux-thunk";
import authSlice from "./reducers/authReducers";
import adminSlice from "./reducers/adminReducer";
import userSlice from "./reducers/userReducer";
import tierSlice from "./reducers/tierReducer";
import gymSlice from "./reducers/fitnessReducer";
import sessionSlice from "./reducers/sessionReducer";
import ReferralSlice from "./reducers/referralsReducer";
import KycReducer from "./reducers/kycReducer";
import TransactionReducer from "./reducers/transctionReducer";
import AccountSlice from "./reducers/accountsReducer";
import CoinsSlice from "./reducers/coinsReducer";
import financialReducer from "./reducers/financialReducer";
import AssestsReducer from "./reducers/assestsReducer";
import feeReducer from "./reducers/feeReducer";
import StatsReducer from "./reducers/statsReducer";
import stakingReducer from "./reducers/stakingReducer";
import swapReducer from "./reducers/swapReducer";
import RewardSettingReducer from "./reducers/rewardsettingReducer";
var window;

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth"],
  blacklist: [],
};

const reducers = combineReducers({
  auth: authSlice,
  admin: adminSlice,
  user: userSlice,
  tier: tierSlice,
  gym: gymSlice,
  session: sessionSlice,
  referral: ReferralSlice,
  kyc: KycReducer,
  transaction: TransactionReducer,
  accounts: AccountSlice,
  coins: CoinsSlice,
  financial: financialReducer,
  assets: AssestsReducer,
  fee: feeReducer,
  stats: StatsReducer,
  staking: stakingReducer,
  swap: swapReducer,
  rewardsetting: RewardSettingReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
}).concat(ReduxThunk);

let enhancedCompose = compose;

// if (__DEV__) {
//   enhancedCompose = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
// }

export const store = configureStore({
  reducer: persistedReducer,
  middleware: enhancedCompose(middleware),
});

export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof reducers>;
// export type AppDispatch = typeof store.dispatch;
