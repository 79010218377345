import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Input } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import styles from "./Accounts.module.scss";
import { useAlert } from "react-alert";
import { getAccounts } from "../../services/accounts";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsData } from "../../store/reducers/accountsReducer";
import Pill from "../_common/Pill/Pill";

const Accounts = () => {
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.accounts);

  const getData = async (page) => {
    try {
      setLoading(true);
      const data = await getAccounts(page);
      dispatch(getAccountsData(data));
      setLoading(false);
    } catch {
      alert.error("Accounts loading failed");
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(1);
  }, []);
  console.log(usersData);
  const columns = [
    {
      dataIndex: "profilePicture",
      key: "profilePicture",
      render: () => (
        <Button
          style={{
            cursor: "default",
          }}
          icon={<UserOutlined />}
          type="primary"
          shape="circle"
          size={1}
        ></Button>
      ),
    },
    {
      title: "Accounts",
      dataIndex: "xaccount",
      key: "accounts",
      // render: (accounts) => (
      //   <div className={styles.amount}>{accounts && accounts}</div>
      // ),
    },
    {
      title: "Level",
      dataIndex: "levelId",
      key: "level",
      render: (level) => <Pill color="#177ddc">{level}</Pill>,
    },
    {
      title: "KYC Status",
      dataIndex: "status",
      key: "kycStatus",
      render: (kycStatus) => (
        <Pill
          color={
            kycStatus === "approved"
              ? "green"
              : kycStatus === "rejected"
              ? "red"
              : "#177ddc"
          }
        >{`${kycStatus}`}</Pill>
      ),
    },
  ];
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Accounts</div>
        </div>
        <div className={styles.divider} />
        <div className={styles.midsection}>
          <Row>
            <Col lg={8} sm={24}>
              <Input prefix={<SearchOutlined />} />
            </Col>
          </Row>
        </div>
        <Table
          loading={loading}
          columns={columns}
          scroll={{ x: true }}
          dataSource={usersData.data}
          pagination={{
            total: usersData.count,
            onChange(e) {
              getData(e);
            },
          }}
        />
      </div>
    </>
  );
};

export default Accounts;
