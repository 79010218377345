import { URL } from "../utills/constants/url.constants";
import HTTP_CLIENT from "../utills/request";
export const getstaking = () => {
  return HTTP_CLIENT.get(URL.GET_STAKING);
};
export const addColdStake = (data) => {
  return HTTP_CLIENT.post(URL.ADD_COLD_STAKE, data);
};
export const addHotStake = (data) => {
  return HTTP_CLIENT.post(URL.ADD_HOT_STAKE, data);
};

export const getColdStaker = () => {
  return HTTP_CLIENT.get(URL.GET_STAKER_COLD_ACCOUNT);
};

export const getHotStaker = () => {
  return HTTP_CLIENT.get(URL.GET_STAKER_HOT_ACCOUNT);
};
