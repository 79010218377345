import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Row, Col, Input } from "antd";
import {
  CloudDownloadOutlined,
  SearchOutlined,
  DollarCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import styles from "./Assets.module.scss";
import { useAlert } from "react-alert";
import AddTrustModal from "../_common/Modal/AddTrustModal";
import ReceiveIssuerModal from "../_common/Modal/ReceiveIssuerModal";
import IssueAssetModal from "../_common/Modal/IssueAssetModal";
import AddIssuerModal from "../_common/Modal/AddIssuerModal";
import AddDistributerModal from "../_common/Modal/AddDistributorModal";
import AddFeeCollectorModal from "../_common/Modal/AddFeeColectorModal";
import { getAssetsData } from "../../store/reducers/assestsReducer";
import { getAssets } from "../../services/assetsService";
import ConfigureAssets from "../_common/Modal/ConfigureAssets";
import Pill from "../_common/Pill/Pill";
const Assets = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.assets);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [viewaddtrust, setViewAddtrust] = useState(false);
  const [viewaddissuer, setViewAddIssuer] = useState(false);
  const [receiveissuer, setReceiveIssuer] = useState(false);
  const [issuseasset, setIssueAsset] = useState(false);
  const [viewadddistributor, setViewAddDistributor] = useState(false);
  const [viewConfigureModel, setViewConfigureModel] = useState(false);
  const [viewFeeCollector, setViewFeeCollector] = useState(false);
  // const [viewCreateSettingModel, setCreateSettingModel] = useState(false);
  const [coin, setCoin] = useState("");
  const [passDistributer, setPassDistributer] = useState("");
  const [passIssure, setPassIssure] = useState("");
  const [key, setKey] = useState("");
  const getData = async (page) => {
    try {
      setLoading(true);
      const data = await getAssets();
      dispatch(
        getAssetsData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("Assets loading failed");
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const viewAddFeeCollector = () => {
    setViewFeeCollector(true);
  };
  const viewAddtrust = () => {
    setViewAddtrust(true);
  };
  const receiveIssuer = () => {
    setReceiveIssuer(true);
  };
  const IssueAsset = (e) => {
    setIssueAsset(true);
    setCoin(e);
  };
  const addIssuer = (e) => {
    setViewAddIssuer(true);
    setCoin(e);
  };
  const addDistributor = () => {
    setViewAddDistributor(true);
  };
  const viewConfigureModelHandler = () => {
    setViewConfigureModel(false);
  };
  // const viewCreateSettingModelHandler = () => {
  //   setCreateSettingModel(false);
  // };

  const cancelViewAddTrustModal = () => {
    setViewAddtrust(false);
  };
  const cancelReceiveIssuerModal = () => {
    setReceiveIssuer(false);
  };
  const cancelIssueAssetModal = () => {
    setIssueAsset(false);
  };
  const cancelViewAddIssuerModal = () => {
    setViewAddIssuer(false);
  };
  const cancelViewAddDistributorModal = () => {
    setViewAddDistributor(false);
  };
  const cancelViewAddFeeCollectorModal = () => {
    setViewFeeCollector(false);
  };
  const columns = [
    {
      dataIndex: "icon",
      key: "icon",
      render: (icon) => (
        <div>
          {icon ? (
            <img style={{ width: "40px" }} alt="img" src={icon} />
          ) : (
            <DollarCircleOutlined />
          )}
        </div>
      ),
    },
    {
      title: "Code",
      dataIndex: "ticker",
      key: "ticker",
    },
    {
      title: "Dist. Request",
      dataIndex: "distributorRequest",
      key: "distributorRequest",
      render: (distributorRequest) => (
        <>
          <Pill
            color={
              distributorRequest == true
                ? "green"
                : distributorRequest == false
                ? "red"
                : "#177ddc"
            }
          >{`${distributorRequest}`}</Pill>
        </>
      ),
    },
    {
      title: "Issuer",
      dataIndex: "issuer",
      key: "issuer",
    },
    {
      title: "Distributor",
      dataIndex: "distributer",
      key: "distributer",
    },

    {
      title: "",
      dataIndex: "addIssuer",
      key: "addIssuer",
      render: (x, record) => (
        <Button
          icon={<CloudDownloadOutlined />}
          type="secondary"
          shape="square"
          size={1}
          onClick={() => {
            addIssuer(record.ticker);
            setKey(record._id);
          }}
        >
          Add Issuer
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "addDistributor",
      key: "addDistributor",
      render: (x, record) => (
        <Button
          icon={<CloudDownloadOutlined />}
          type="secondary"
          shape="square"
          size={1}
          onClick={() => {
            addDistributor(record.ticker);
          }}
        >
          Add Distributor
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "receive",
      key: "receive",
      render: (distributer, record) => (
        <Button
          icon={<CloudDownloadOutlined />}
          type="secondary"
          shape="square"
          size={1}
          onClick={() => {
            receiveIssuer();
            setPassDistributer(record.distributer);
          }}
        >
          Receive
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "addtrust",
      key: "addtrust",
      render: (ale, record) => (
        <Button
          icon={<CloudDownloadOutlined />}
          type="secondary"
          shape="square"
          size={1}
          onClick={() => {
            viewAddtrust();
            setCoin(record.ticker);
            setPassIssure(record.issuer);
          }}
        >
          Add Trust
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "issueAssets",
      key: "issueAssets",
      render: (issueAssets, record) => (
        <Button
          icon={<CloudDownloadOutlined />}
          type="secondary"
          shape="square"
          size={1}
          onClick={() => {
            IssueAsset(record.ticker);
            setPassDistributer(record.distributer);
          }}
        >
          Issue Assets
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "details",
      key: "details",
      render: (ticker, record) => (
        <Button
          icon={<CloudDownloadOutlined />}
          type="secondary"
          shape="square"
          size={1}
          onClick={() => {
            setViewConfigureModel(true);
            setCoin(record.ticker);
          }}
        >
          Configure
        </Button>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "setting",
    //   key: "setting",
    //   render: (shortBalance, record) => (
    //     <Button
    //       onClick={() => {
    //         setCreateSettingModel(true);
    //         setCoin(record.ticker);
    //       }}
    //       icon={<SettingOutlined />}
    //       shape="round"
    //       size={1}
    //     ></Button>
    //   ),
    // },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Anchor Assets Management</div>
        </div>
        <div className={styles.divider} />

        <div className={styles.midsection}>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col lg={8} sm={24}>
              <Input prefix={<SearchOutlined />} />
            </Col>
            <Button
              className={styles.feecollector_btn}
              type="primary"
              icon={<CloudUploadOutlined />}
              onClick={() => {
                viewAddFeeCollector();
              }}
            >
              Add Fee Collector
            </Button>
          </Row>
        </div>
        <Table
          loading={loading}
          columns={columns}
          // pagination={false}
          scroll={{ x: true }}
          dataSource={data}
        />
      </div>
      <AddIssuerModal
        onCancel={cancelViewAddIssuerModal}
        visible={viewaddissuer}
        coin={coin}
        key={key}
      />
      <AddDistributerModal
        onCancel={cancelViewAddDistributorModal}
        visible={viewadddistributor}
        coin={coin}
      />
      <AddTrustModal
        onCancel={cancelViewAddTrustModal}
        visible={viewaddtrust}
        coin={coin}
        passIssure={passIssure}
      />
      <ReceiveIssuerModal
        onCancel={cancelReceiveIssuerModal}
        visible={receiveissuer}
        Distributer={passDistributer}
      />
      <IssueAssetModal
        onCancel={cancelIssueAssetModal}
        distributer={passDistributer}
        visible={issuseasset}
        coin={coin}
      />
      <ConfigureAssets
        visible={viewConfigureModel}
        onCancel={viewConfigureModelHandler}
        coin={coin}
      />
      <AddFeeCollectorModal
        onCancel={cancelViewAddFeeCollectorModal}
        visible={viewFeeCollector}
      />
      {/* <CreateSetting
        visible={viewCreateSettingModel}
        onCancel={viewCreateSettingModelHandler}
        coin={coin}
      /> */}
    </>
  );
};

export default Assets;
