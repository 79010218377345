import React, { useEffect, useState } from "react";
import { Table, Popconfirm } from "antd";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./Fee.module.scss";
import { useAlert } from "react-alert";
import { Row, Col, Button, Input } from "antd";
import { getFee, setDeleteSetting } from "../../services/fee.service";
import { getFeeData } from "../../store/reducers/feeReducer";
import { useDispatch, useSelector } from "react-redux";
import CreateSetting from "../_common/Modal/CreateSetting";

const Fee = () => {
  const [loading, setLoading] = useState(false);
  const [viewCreateSettingModel, setCreateSettingModel] = useState(false);
  const [postOrPatch, setPostOrPatch] = useState(false);
  const [reload, setReload] = useState(false);
  const alert = useAlert();
  const [createSettingPayload, setCreateSettingPayload] = useState({
    levelId: 1, //number
    coinShort: "", //string
    isHybridAsset: true, //boolean
    depositFeeFixed: 0, //number
    depositFeePercent: 0, //number
    depositMinAmount: 0, //number
    depositMaxAmount: 0, //number
    withdrawFeeFixed: 0, //number
    withdrawFeePercent: 0, //number
    withdrawMinAmount: 0, //number
    withdrawMaxAmount: 0, //number
  });
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.fee);

  const viewCreateSettingModelHandler = () => {
    setCreateSettingModel(false);
  };

  const changeReloadStateHandler = () => {
    setReload((state) => !state);
  };
  const getData = async () => {
    try {
      setLoading(true);
      const data = await getFee();

      dispatch(
        getFeeData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("Transaction loading failed");
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [reload]);

  const deleteFeeSettingHandler = async (data) => {
    try {
      const response = await setDeleteSetting(data);
      console.log(response);
      setReload((state) => !state);
      alert.success(response.data.message && response.data.message);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message && err.response.data.message);
      } else {
        alert.error("Deletion failed");
      }
    }
  };

  const setCreateSettingPayloadData = (prams) => {
    setCreateSettingPayload(prams);
  };

  const columns = [
    {
      title: "Coin",
      dataIndex: "coinShort",
      key: "coinShort",
    },
    {
      title: "Level Id",
      dataIndex: "levelId",
      key: "levelId",
      filters: [
        { text: "Level 1", value: 1 },
        { text: "Level 2", value: 2 },
        { text: "Level 3", value: 3 },
      ],
      onFilter: (value, record) => record.levelId === value,
    },
    {
      title: "Withdraw Max Amount",
      dataIndex: "withdrawMaxAmount",
      key: "withdrawMaxAmount",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "Withdraw Min Amount",
      dataIndex: "withdrawMinAmount",
      key: "withdrawMinAmount",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "Withdraw Fee Percent",
      dataIndex: "withdrawFeePercent",
      key: "withdrawFeePercent",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "Withdraw Fee Fixed",
      dataIndex: "withdrawFeeFixed",
      key: "withdrawFeeFixed",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "Deposit Max Amount",
      dataIndex: "depositMaxAmount",
      key: "depositMaxAmount",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "Deposit Min Amount",
      dataIndex: "depositMinAmount",
      key: "depositMinAmount",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "Deposit Fee Percent",
      dataIndex: "depositFeePercent",
      key: "depositFeePercent",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "Deposit Fee Fixed",
      dataIndex: "depositFeeFixed",
      key: "depositFeeFixed",
      render: (account) => {
        return <div className={styles.amount}>{account && account}</div>;
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (account, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete this fee setting?"
            onConfirm={() => {
              deleteFeeSettingHandler({
                levelId: record.levelId,
                coinShort: record.coinShort,
              });
            }}
            //  onCancel={cancel}
            placement="topLeft"
            okText="Yes"
            cancelText="No"
          >
            <Button
              // onClick={() => {
              //   setCreateSettingModel(true);
              //   setCreateSettingPayload(record);
              //   setPostOrPatch(true);
              // }}
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        );
      },
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (account, record) => {
        return (
          <Button
            onClick={() => {
              setCreateSettingModel(true);
              setCreateSettingPayload(record);
              setPostOrPatch(true);
            }}
            icon={<EditOutlined />}
          ></Button>
        );
      },
    },
  ];
  // const antIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  // const tableLoading = {
  //   spinning: loading,
  //   indicator: <Spin indicator={antIcon} />,
  // };
  // const getFullDate = (date) => {
  //   const dateAndTime = date.split("T");
  //   return dateAndTime[0].split("-").reverse().join("-");
  // };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Fee Settings</div>
        </div>
        <div className={styles.divider} />
        <div className={styles.midsection}>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col lg={8} sm={24}>
              <Input prefix={<SearchOutlined />} />
            </Col>

            <Button
              className={styles.addfeebtn}
              onClick={() => {
                setPostOrPatch(false);
                setCreateSettingModel(true);
                setCreateSettingPayload({
                  levelId: 1, //number
                  coinShort: "", //string
                  isHybridAsset: true, //boolean
                  depositFeeFixed: 0, //number
                  depositFeePercent: 0, //number
                  depositMinAmount: 0, //number
                  depositMaxAmount: 0, //number
                  withdrawFeeFixed: 0, //number
                  withdrawFeePercent: 0, //number
                  withdrawMinAmount: 0, //number
                  withdrawMaxAmount: 0, //number
                });
              }}
              type="primary"
              icon={<CloudUploadOutlined />}
            >
              Add Fee
            </Button>
          </Row>
        </div>
        <Table
          loading={loading}
          columns={columns}
          pagination={false}
          scroll={{ x: true }}
          dataSource={data}
        />
        <CreateSetting
          visible={viewCreateSettingModel}
          onCancel={viewCreateSettingModelHandler}
          createSettingPayload={createSettingPayload}
          setCreateSettingPayload={setCreateSettingPayloadData}
          postOrPatch={postOrPatch}
          changeReloadStateHandler={changeReloadStateHandler}
        />
      </div>
    </>
  );
};

export default Fee;
