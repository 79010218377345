import { SendOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Table,
  Divider,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getAssets } from "../../../services/assetsService";
import { getAssetsData } from "../../../store/reducers/assestsReducer";
import {
  getRewardSetting,
  addRewardSetting,
} from "../../../services/rewardsettingService";
import { getRewardsettingData } from "../../../store/reducers/rewardsettingReducer";
function RewardSetting({ visible, onCancel, postOrPatch }) {
  const coinRef = useRef();
  const [loading, setLoading] = useState(false);
  const [createRewardSettingPayload, setRewardCreateSettingPayload] = useState({
    coin: "", //string
    month: "", //number
    year: "", //number
  });
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const { data } = useSelector((state) => state.rewardsetting);
  console.log("data", data);
  const [check, setCheck] = useState(false);
  function onChange(e) {
    setCheck(e.target.checked);
  }

  const coins = useSelector((state) => state.assets);
  const getAssetsDataforCoins = async () => {
    try {
      setLoading(true);
      const data = await getAssets();
      dispatch(
        getAssetsData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("Assets loading failed!");
      setLoading(false);
    }
  };

  useEffect(() => {
    coins.data.length < 1 && getAssetsDataforCoins();
  }, []);
  useEffect(() => {
    getRewardSettingData();
  }, []);
  const getRewardSettingData = async () => {
    try {
      setLoading(true);
      const response = await getRewardSetting();
      dispatch(
        getRewardsettingData({
          data: response,
        })
      );
      setLoading(false);
      setReload((state) => !state);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message && err.response.data.message);
        setLoading(false);
        onCancel_();
      } else {
        setLoading(false);
        onCancel_();
        alert.error("Failed");
      }
    }
  };
  const handleAddRewardSetting = async () => {
    try {
      setLoading(true);
      const response = await addRewardSetting(createRewardSettingPayload);
      alert.success(response.data.message && response.data.message);
      setLoading(false);
      // onCancel_();
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message && err.response.data.message);
        setLoading(false);
        onCancel_();
      } else {
        setLoading(false);
        onCancel_();
        alert.error("Failed");
      }
    }
  };

  const onCancel_ = () => {
    onCancel();
    setRewardCreateSettingPayload({
      coin: "", //string
      month: "", //number
      year: "", //number
    });
    // setCheck(false);
  };
  const columns = [
    {
      title: "Coin",
      dataIndex: "coin",
      key: "coin",
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
  ];
  const changeReloadStateHandler = () => {
    setReload((state) => !state);
  };
  return (
    <Modal
      title="Reward Settings"
      visible={visible}
      onCancel={() => {
        !loading && onCancel_();
      }}
      centered
      width={1000}
      footer={<p></p>}
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8} xl={8}>
            <label>Coin</label>
            {/* {postOrPatch ? (
              <Input ref={coinRef} value={createRewardSettingPayload.coinShort} />
            ) : ( */}
            <Select
              value={createRewardSettingPayload.coin}
              onChange={(e) => {
                setRewardCreateSettingPayload({
                  ...createRewardSettingPayload,
                  coin: e,
                });
              }}
              style={{ width: "100%" }}
            >
              <Select.Option value="BisonArmy">BisonArmy</Select.Option>
              <Select.Option value="XRP">XRP</Select.Option>
              {/* {coins.data.map((element) => (
                <Select.Option key={element.key} value={element.ticker}>
                  {element.ticker}
                </Select.Option>
              ))} */}
            </Select>
            {/* )} */}
          </Col>
          <Col xs={24} lg={8} xl={8}>
            <label>Month</label>
            <InputNumber
              min="0"
              max="100"
              step="0.00"
              style={{ width: "100%" }}
              type="number"
              value={createRewardSettingPayload.month}
              onChange={(e) => {
                console.log(e);
                setRewardCreateSettingPayload({
                  ...createRewardSettingPayload,
                  month: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={8} xl={8}>
            <label>Year</label>
            <InputNumber
              min="0"
              max="100"
              step="0.00"
              style={{ width: "100%" }}
              type="number"
              value={createRewardSettingPayload.year}
              onChange={(e) => {
                console.log(e);
                setRewardCreateSettingPayload({
                  ...createRewardSettingPayload,
                  year: +e,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<SendOutlined />}
              loading={loading}
              //   disabled={check && createSettingPayload.coinShort ? false : true}
              onClick={handleAddRewardSetting}
              type="primary"
            >
              Add Reward Settings
            </Button>
          </Col>
        </Row>
        <Divider />
        <Table
          loading={loading}
          columns={columns}
          pagination={false}
          scroll={{ x: true }}
          dataSource={data}
        />
      </div>
    </Modal>
  );
}

export default RewardSetting;
