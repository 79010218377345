import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
};

export const RewardSettingReducer = createSlice({
  name: "rewardsetting",
  initialState,
  reducers: {
    getRewardsettingData(state, action) {
      state.data = action.payload.data.data.payload;
      //   state.count = action.payload;
    },
  },
});

export const { getRewardsettingData } = RewardSettingReducer.actions;

export default RewardSettingReducer.reducer;
