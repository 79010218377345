import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const AssetsReducer = createSlice({
  name: "assets",
  initialState,
  reducers: {
    getAssetsData(state, action) {
      state.data = action.payload.data.data.payload;
    },
  },
});

export const { getAssetsData } = AssetsReducer.actions;

export default AssetsReducer.reducer;
