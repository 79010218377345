import axios, { AxiosInstance, AxiosInterceptorManager } from "axios";
import { store } from "../store";
import { setIsLoggedIn } from "../store/reducers/authReducers";
import { BASE_URL } from "./constants/app.constants";

export const HTTP_CLIENT = axios.create({
  baseURL: BASE_URL,
});

HTTP_CLIENT.interceptors.request.use(
  (config) => {
    // const accessToken = "";
    const { accessToken } = store.getState().auth;
    console.log("acccc", accessToken);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (!accessToken) {
      store.dispatch(
        setIsLoggedIn({
          isLoggedIn: false,
        })
      );
    }
    return config;
  },

  (err) => {
    return Promise.reject(err);
  }
);

export default HTTP_CLIENT;
