import axios from "axios";
import { URL } from "../utills/constants/url.constants";
import HTTP_CLIENT from "../utills/request";
export const getFee = () => axios.get(URL.GET_FEE);
export const setCreateSetting = (data) => {
  return HTTP_CLIENT.post(URL.SET_CREATE_SETTING, data);
};
export const setUpdateSetting = (data) => {
  return HTTP_CLIENT.patch(URL.SET_UPDATE_SETTING, data);
};
export const setDeleteSetting = (data) => {
  return HTTP_CLIENT.post(URL.SET_DELETE_SETTING, data);
};
export const setFeecollector = (data) => {
  return HTTP_CLIENT.post(URL.SET_FEE_COLLECTOR, data);
};
export const getFeeCollector = () => {
  return HTTP_CLIENT.get(URL.GET_FEE_COLLECTOR);
};
