import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
  feeAddress: "",
};

export const feeReducer = createSlice({
  name: "fee",
  initialState,
  reducers: {
    getFeeData(state, action) {
      state.data = action.payload.data.data.payload;
      state.count = action.payload.data.data.payload.count;
    },
    getKYCDetailData(state, action) {
      state.detail = action.payload.data.data.payload;
    },
    getFeeCollectorData(state, action) {
      state.feeAddress = action.payload.data.data.payload.address;
      // console.log("feeAddress", state.feeAddress.data.data.payload.address);
      // console.log("feeAddressssssss", state.feeAddress);
    },
  },
});

export const { getFeeData, getFeeCollectorData } = feeReducer.actions;

export default feeReducer.reducer;
