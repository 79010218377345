import React, { useState } from "react";
import { Menu } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  HomeOutlined,
  CreditCardOutlined,
  AppstoreOutlined,
  ShoppingOutlined,
  MenuOutlined,
  BarChartOutlined,
  SwapOutlined,
  StockOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { setIsLoggedIn } from "../../../store/reducers/authReducers";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import NotificationModal from "../../Modals/NotificationsModal/Notifications";
import styles from "./Navigation.module.scss";

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const renderLocation = () => {
    switch (location.pathname) {
      case "/":
        return ["1"];
      case "/master-wallet":
        return ["2"];
      case "/assets":
        return ["3"];
      case "/fee":
        return ["4"];
      case "/kyc":
        return ["5"];
      case "/accounts":
        return ["6"];
      case "/transactions":
        return ["7"];
      case "/swap-account":
        return ["8"];
      case "/swap-account":
        return ["9"];
      case "/staking-info":
        return ["10"];
      default:
        return ["1"];
    }
  };

  const handleLocalStorage = (e) => {
    // window.localStorage.removeItem("persist:root");
    dispatch(
      setIsLoggedIn({
        isLoggedIn: false,
        accessToken: "",
      })
    );
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleNotificationModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={renderLocation()}>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to="/">Dashboard</Link>
        </Menu.Item>
        {/* <Menu.Divider /> */}
        <Menu.Item key="2" icon={<CreditCardOutlined />}>
          <Link to="/master-wallet">Master Wallet</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<AppstoreOutlined />}>
          <Link to="/assets">Assets</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<BarChartOutlined />}>
          <Link to="/fee">Fee</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<ShoppingOutlined />}>
          <Link to="/kyc">KYC Management</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<UserOutlined />}>
          <Link to="/accounts">Accounts</Link>
        </Menu.Item>

        {/* <Menu.Divider /> */}
        <Menu.Item key="7" icon={<MenuOutlined />}>
          <Link to="/transactions">Transactions</Link>
        </Menu.Item>

        <Menu.Item key="8" icon={<SwapOutlined />}>
          <Link to="/swap-account">Swap</Link>
        </Menu.Item>

        <Menu.Item key="9" icon={<StockOutlined />}>
          <Link to="/staking-info">Staking Info</Link>
        </Menu.Item>

        {/* <Menu.Item key="10" icon={<SettingOutlined />}>
          <Link to="/settings">Settings</Link>
        </Menu.Item> */}
        <Menu.Item
          key="11"
          icon={<LogoutOutlined />}
          onClick={handleLocalStorage}
        >
          Logout
        </Menu.Item>
        <Menu.Divider />
      </Menu>
      <NotificationModal isOpen={isOpen} toggle={toggleNotificationModal} />
    </>
  );
};

export default Navigation;
