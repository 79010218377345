import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tiers: {},
};

export const tierSlice = createSlice({
  name: "tier",
  initialState,
  reducers: {
    setTiers(state, action) {
      state.tiers = action.payload;
    },
    editTierAction(state, action) {
      const index = state.tiers.data.findIndex(
        (item) => item.id === action.payload.id
      );
      state.tiers.data[index] = action.payload;
    },
    setUpdatedTiers(state, action) {
      const newData = state.tiers.data.filter(
        (item) => item.id !== action.payload
      );
      state.tiers.data = newData;
    },
    setNewTier(state, action) {
      const index = state.tiers.data.push();
      state.tiers.data[index] = action.payload;
    },
  },
});

export const { setTiers, setUpdatedTiers, editTierAction, setNewTier } =
  tierSlice.actions;
export default tierSlice.reducer;
