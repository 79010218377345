import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [],
};

const CoinsSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    getCoinsData(state, actions) {
      state.data = actions.payload.data.payload;
    },
  },
});

export const { getCoinsData } = CoinsSlice.actions;
export default CoinsSlice.reducer;
