import React, { useState } from "react";
import GenericModal from "../../_common/genericModal/index";
import { ThreeDots } from "react-loader-spinner";
import { useAlert } from "react-alert";
import styles from "../../Modals/DeleteGym/DeleteGym.module.scss";
const DeleteHook = (props) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const handleDeleteGym = async () => {
    // try {
    //   setLoading(true);
    //   const Deleted = await deleteGym(selectedRecordId);
    //   dispatch(setUpdatedGyms(selectedRecordId));
    //   toggle();
    //   // const updatedData = [...gyms, Deleted.data.data];
    //   // dispatch(setGyms(updatedData));

    //   setLoading(false);
    //   alert.show("successfully deleted", { type: "success" });
    // } catch (error) {
    //   alert.show(error.response.data.message, { type: "error" });
    //   setLoading(false);
    // }
  };
  return (
    <>
      <GenericModal isOpen={props.isOpen} toggle={props.Toggle}>
        <div className={styles.mainheading}>Do You Really want to Delete Hook?</div>
        <button
          className={styles.button}
          disabled={loading}
          onClick={handleDeleteGym}
        >
          {loading ? (
            <ThreeDots
              height="30"
              width="30"
              color="white"
              ariaLabel="loading"
              marginBottom="10px"
            />
          ) : (
            "Delete"
          )}
        </button>
      </GenericModal>
    </>
  );
};

export default DeleteHook;
