import React, { useState } from "react";
import { Table, Spin, Menu } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  DeleteOutlined,
  EyeOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styles from "./Hooks.module.scss";
import { useAlert } from "react-alert";
import { Row, Col, Dropdown, Button, Input } from "antd";
import DeleteHook from "../_common/Modal/DeleteModal";
const Hooks = () => {
  const alert = useAlert();
  const loading = false;
const [isOpen,setIsOpen] = useState(false);
const Toogle = () => {
  setIsOpen(false);
}
  const usersData = [
    {
      id: "c1c539cc-9",
      address: "1FBPFM4YK...eW119zRBzt",
      event: "confirmed-tx",
      token: "1FBPFM4YK...eW119zRBzt",
    },
    {
      id: "c1c539cc-9",
      address: "1FBPFM4YK...eW119zRBzt",
      event: "confirmed-tx",
      token: "1FBPFM4YK...eW119zRBzt",
    },
    {
      id: "c1c539cc-9",
      address: "1FBPFM4YK...eW119zRBzt",
      event: "confirmed-tx",
      token: "1FBPFM4YK...eW119zRBzt",
    },
    {
      id: "c1c539cc-9",
      address: "1FBPFM4YK...eW119zRBzt",
      event: "confirmed-tx",
      token: "1FBPFM4YK...eW119zRBzt",
    },
  ];
  // pagination //
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      render: (date) => getFullDate(date),
    },
    // {
    //   title: "Current Tier",
    //   key: "currentTier",
    //   dataIndex: "currentTier",
    // },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: () => (
        <>
          <EyeOutlined style={{ marginRight: "10px", cursor: "pointer" }} />
          <DeleteOutlined style={{ cursor: "pointer" }} onClick={handleDelteicon}/>
        </>
      ),
    },
  ];
  const handleDelteicon = () => {
    console.log("icone selected");
    setIsOpen(true);
  }
  const menu = (
    <Menu>
      <Menu.Item key="1">1st menu item</Menu.Item>
      <Menu.Item key="2">2nd menu item</Menu.Item>
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );
  const antIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={antIcon} />,
  };
  const getFullDate = (date) => {
    const dateAndTime = date.split("T");
    return dateAndTime[0].split("-").reverse().join("-");
  };
  const handleSearch = (e)=> {
    console.log(e.target.value)
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Hooks</div>
        </div>
        <div className={styles.divider} />
        <div className={styles.midsection}>
          <Row>
            <Col lg={16}>
              <Dropdown overlay={menu}>
                <Button>
                  BTC List <DownOutlined size="small" />
                </Button>
              </Dropdown>
            </Col>
            <Col lg={8} sm={24}>
              <Input prefix={<SearchOutlined />} onChange={handleSearch} />
            </Col>
          </Row>
        </div>
        <Table
          loading={loading ? tableLoading : false}
          columns={columns}
          // pagination={(state.pageCount, state.page)}
          scroll={{ x: true }}
          dataSource={usersData}
        />
      </div>
      <DeleteHook isOpen={isOpen} selectedRecordId="12" toogle={Toogle}/>
    </>
  );
};

export default Hooks;
