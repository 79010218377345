import React, { useEffect, useState } from "react";
import { Table, Typography, Row, Col, Input } from "antd";
import { DollarCircleOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./MasterWallet.module.scss";
import { useAlert } from "react-alert";
import Pill from "../_common/Pill/Pill";
import { useDispatch, useSelector } from "react-redux";
import { getCoins } from "../../services/coins";
import { getCoinsData } from "../../store/reducers/coinsReducer";

const MasterWallet = () => {
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.coins);

  const getData = async () => {
    try {
      setLoading(true);
      const data = await getCoins();
      dispatch(getCoinsData(data));
      setLoading(false);
    } catch {
      alert.error("Master Wallet loading failed");
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "",
      dataIndex: "icon",
      key: "coinsName",
      render: (coinsName) => (
        <div>
          {coinsName ? (
            <img className={styles.icon} alt="img" src={coinsName} />
          ) : (
            <DollarCircleOutlined />
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "ticker",
      key: "name",
    },
    {
      title: "Crypto Distributor Address",
      dataIndex: "distributer",
      key: "cryptoDistributer",
      render: (cryptoDistributer) => (
        <div className={styles.pill}>
          <Typography.Text>{cryptoDistributer}</Typography.Text>
          {/* <div className={styles.pillPadding}>
            <Pill color="blue">
              <SyncOutlined style={{ cursor: "pointer" }} />
              {cryptoDistributer}
            </Pill>
          </div> */}
        </div>
      ),
    },
    {
      title: "Crypto Cold Address",
      dataIndex: "issuer",
      key: "cryptoCold",
      render: (cryptoCold) => (
        <div className={styles.pill}>
          <Typography.Text>{cryptoCold}</Typography.Text>
          {/* <div className={styles.pillPadding}>
            <Pill color="blue">
              <SyncOutlined style={{ cursor: "pointer" }} />
              {cryptoCold}
            </Pill>
          </div> */}
        </div>
      ),
    },
    // {
    //   title: "Dist Short Balance",
    //   dataIndex: "shortBalance",
    //   key: "shortBalance",
    //   render: (shortBalance) => <Pill color="blue">{shortBalance}</Pill>,
    // },
    // {
    //   title: "Network Free",
    //   dataIndex: "networkFree",
    //   key: "networkFree",
    // },
    {
      title: "ERC20",
      dataIndex: "isErc20",
      key: "erc20",
      render: (shortBalance) => (
        <Pill color="red">{`${shortBalance}`.toUpperCase()}</Pill>
      ),
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Master Wallet</div>
        </div>
        <div className={styles.divider} />
        <section>
          <div className={styles.midsection}>
            <Row>
              <Col lg={8} sm={24}>
                <Input prefix={<SearchOutlined />} />
              </Col>
            </Row>
          </div>
          <Table
            loading={loading}
            columns={columns}
            scroll={{ x: true }}
            dataSource={usersData.data}
          />
        </section>
      </div>
    </>
  );
};

export default MasterWallet;
