import { SendOutlined } from "@ant-design/icons";
import { Modal, Input, Row, Col, Button, Label } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { getIssuer } from "../../../services/assetsService";
import {
  setFeecollector,
  getFeeCollector,
} from "../../../services/fee.service";
import { useDispatch, useSelector } from "react-redux";
import { getFeeCollectorData } from "../../../store/reducers/feeReducer";
const AddFeeCollectorModal = ({ visible, onCancel }) => {
  useEffect(() => {
    if (visible === true) {
      getFeecollectorData();
    }
  }, [visible]);
  const dispatch = useDispatch();
  const [issuerSeed, setIssuerSeed] = useState("");
  const { feeAddress } = useSelector((state) => state.fee);
  console.log("fees", feeAddress);
  //   const coinRef = useRef();
  const seedRef = useRef();
  const [loading, setLoading] = useState(false);
  const [genloading, setGenLoading] = useState(false);
  alert = useAlert();
  const getFeecollectorData = async () => {
    try {
      setLoading(true);
      const data = await getFeeCollector();
      dispatch(
        getFeeCollectorData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      // alert.error("Fee Collector loading failed!");
      setLoading(false);
    }
  };
  const generateSeed = async () => {
    try {
      setGenLoading(true);
      const data = await getIssuer();
      setIssuerSeed(data.data.payload);
      setGenLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setGenLoading(false);
      } else {
        alert.error("Generating failed!");
        setGenLoading(false);
      }
    }
  };
  const setFeeCollector = async () => {
    try {
      setLoading(true);
      const response = await setFeecollector({
        wallet_seed: seedRef.current.props.value,
        // coinSymbol: coinRef.current.props.value,
      });
      alert.show(response.data.payload);
      setLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setLoading(false);
      } else {
        alert.error("Adding issuer failed");
        setLoading(false);
      }
    }
  };
  const onCancelHandler = () => {
    onCancel();
    setIssuerSeed("");
  };
  return (
    <Modal
      //   key={key}
      title="Add Fee Collector"
      visible={visible}
      onCancel={!loading && onCancelHandler}
      footer={
        feeAddress && feeAddress ? (
          <>
            <Button icon={<SendOutlined />} type="primary" disabled={true}>
              Add Fee Collector
            </Button>
          </>
        ) : (
          <>
            <Button
              icon={<SendOutlined />}
              loading={loading}
              onClick={setFeeCollector}
              type="primary"
            >
              Add Fee Collector
            </Button>
          </>
        )
      }
      centered
    >
      <div>
        <Row gutter={[16, 16]}>
          {feeAddress && feeAddress ? (
            <>
              <p>Already have a fee collector</p>
              <Input value={feeAddress} />
            </>
          ) : (
            <>
              <Col lg={24}>
                <Input ref={seedRef} value={issuerSeed} />
              </Col>
              <Col lg={24}>
                <Button loading={genloading} onClick={generateSeed}>
                  Generate Issuer
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
    </Modal>
  );
};
export default AddFeeCollectorModal;
