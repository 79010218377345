import React from "react";
import styles from "./graph.module.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Jan",
    uv: 7000,
  },
];

const Graph = ({ data }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const graphData =
    data &&
    data.map((element) => ({
      name: months[element._id],
      uv: element.numberofTransactions,
    }));
  return (
    <div className={styles.graphWrapper}>
      <ResponsiveContainer width="96%" aspect={3}>
        <BarChart
          data={graphData}
          // margin={{
          //   top: 5,
          //   right: 30,
          //   left: 20,
          //   bottom: 5,
          // }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="uv" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default Graph;
