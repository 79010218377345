import { BASE_URL } from "../constants/app.constants";

export const URL = {
  // LOGIN: BASE_URL + "admin/auth/login",
  // VERIFY_EMAIL_CODE: BASE_URL + "admin/auth/verify-login",
  // BAN_USER: BASE_URL + "admin/user/ban",
  // UNBAN_USER: BASE_URL + "admin/unban-user",
  // MULTIPLE_USERS: BASE_URL + "admin/user",
  // MULTIPLE_TIERS: BASE_URL + "tier",
  // CREATE_NEW_TIER: BASE_URL + "tier",
  // DELETE_TIER: BASE_URL + `tier`,
  // EDIT_TIER: BASE_URL + `tier`,
  // MULTIPLE_GYM_CENTER: BASE_URL + "featured-gym",
  // CREATE_NEW_GYM: BASE_URL + "featured-gym/create",
  // DELETE_GYM: BASE_URL + `featured-gym`,
  // EDIT_GYM: BASE_URL + `featured-gym`,
  // FETCH_LEST_OF_GYMS: BASE_URL + `featured-gym/fetch-list`,
  // MULTIPLE_SESSIONS: BASE_URL + `session-setting`,
  // CREATE_NEW_SESSION: BASE_URL + `session-setting`,
  // EDIT_SESSION: BASE_URL + `session-setting`,
  // DELETE_SESSION: BASE_URL + `session-setting`,
  // MULTIPLE_REFERRALS: BASE_URL + `referral-setting`,
  // CREATE_NEW_REFERRAL: BASE_URL + `referral-setting`,
  // EDIT_REFERRAL: BASE_URL + `referral-setting`,
  // DELETE_REFERRAL: BASE_URL + `referral-setting`,
  // SEND_NOTIFICATION_TO_ALL: BASE_URL + "admin/notification/send-to-all",
  ///KMWallet
  Auth_LOGIN: BASE_URL + "auth/admin-login",
  GET_TRANSCTION: BASE_URL + "transaction/admin/getTransactions",
  GET_KYCS: BASE_URL + "kyc/admin/getKycs",
  SET_KYCS_STATUS: BASE_URL + "kyc/admin/changeStatus",
  GET_KYCS_DETAILS: BASE_URL + "kyc",
  GET_ACCOUNTS: BASE_URL + "kyc/admin/getAccounts",
  GET_COINS: BASE_URL + "coin/admin/getCoins",
  // GET_FINANCIAL: BASE_URL +"coin/admin/getCoins";
  GET_ASSETS: BASE_URL + "coin/admin/getAssets",
  SET_ISSUER: BASE_URL + "issuer/add-issuer",
  GET_ISSUER: BASE_URL + "xrpl/generate-seed",
  GET_DISTRIBUTER: BASE_URL + "xrpl/generate-seed",
  SET_DISTRIBUTER: BASE_URL + "distributor/add-distributor",
  SET_CONFIGURE: BASE_URL + "xrpl/configure-hot-and-cold",
  SET_ISSUEASSETS: BASE_URL + "xrpl/issuer/issueAssets",
  SET_TRUST: BASE_URL + "xrpl/add-trust-btw-cold-user",
  SET_CREATE_SETTING: BASE_URL + "fee/create-setting",
  SET_UPDATE_SETTING: BASE_URL + "fee/update-setting",
  SET_DELETE_SETTING: BASE_URL + "fee/delete-setting",
  GET_FEE: BASE_URL + "fee/find-setting",
  GET_STATS: BASE_URL + "account/admin/stats",
  SET_FEE_COLLECTOR: BASE_URL + "fee-collector/add-fee-collector",
  GET_FEE_COLLECTOR: BASE_URL + "fee-collector/get-fee-collector",
  GET_STAKING: BASE_URL + "staking/getStakingAccounts",
  SET_SWAP_ACCOUNT: BASE_URL + "swap-master/add",
  ADD_COLD_STAKE: BASE_URL + "staking/add-staker-cold",
  ADD_HOT_STAKE: BASE_URL + "staking/add-staker-hot",
  GET_STAKER_COLD_ACCOUNT: BASE_URL + "staking/get-staker-cold-account",
  GET_STAKER_HOT_ACCOUNT: BASE_URL + "staking/get-staker-hot-account",
  GET_SWAP_DEATIL: BASE_URL + "swap-master",
  DELETE_SWAP_ACCOUNT: BASE_URL + "swap-master/delete",
  CONFIGURE_SWAP_ACCOUNT: BASE_URL + "swap-master/configure",
  ISSUE_ASSETS_SWAP_ACCOUNT: BASE_URL + "swap-master/issueAssets",
  UPDATE_SWAP_ACCOUNT: BASE_URL + "swap-master/update",
  GET_REWARD_SETTING: BASE_URL + "staking/reward-setting",
  ADD_REWARD_SETTING: BASE_URL + "staking/reward-setting",
};
