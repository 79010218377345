import React from "react";
import InfoIcon from "./icons/InfoIcon";
import SuccessIcon from "./icons/SuccessIcon";
import ErrorIcon from "./icons/ErrorIcon";
import CloseIcon from "./icons/CloseIcon";

const alertStyle = {
  backgroundColor: "#ffffff",
  color: "black",
  padding: "10px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "400px",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  boxSizing: "border-box",
  fontSize: "14px",
  alignItems: "center",
};

const buttonStyle = {
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  textAlign: "center",
};

const centerItems = {
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
};

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <div
      style={{
        ...alertStyle,
        ...style,
      }}
    >
      <div style={centerItems}>
        {options.type === "info" && <InfoIcon />}
        {options.type === "success" && <SuccessIcon />}
        {options.type === "error" && <ErrorIcon />}
      </div>
      <div style={centerItems}>
        <span>{message}</span>
      </div>
      <div style={centerItems}>
        <button style={buttonStyle} onClick={close}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default AlertTemplate;
