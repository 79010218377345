import { CopyOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, InputNumber, Modal, Row } from "antd";
import React, { useRef, useState } from "react";
import { setConfigure } from "../../../services/assetsService";
import * as Yup from "yup";
import InputError from "../InputError/InputError";
import { useFormik } from "formik";

const validationSchema = Yup.object({
  hot_wallet_seed: Yup.string()
    .required("Secret key is required")
    .min(29, "The length of Secret key must be 29")
    .max(29, "The length of Secret key must be 29"),
  cold_wallet_seed: Yup.string()
    .required("Secret key is required")
    .min(29, "The length of Secret key must be 29")
    .max(29, "The length of Secret key must be 29"),
});

const initialConfigurePayloadState = {
  cold_wallet_seed: "",
  coinSymbol: "",
  hot_wallet_seed: "",
  DefaultIssueAmount: 0,
};

function ConfigureAssets({ detail, visible, onCancel, Distributer, coin }) {
  const coinRef = useRef();
  const [loading, setLoading] = useState(false);
  const [configurePayload, setConfigurePayload] = useState(
    initialConfigurePayloadState
  );
  const [check, setCheck] = useState(false);
  const formik = useFormik({
    initialValues: initialConfigurePayloadState,
    validationSchema,
    onSubmit: (e) => {
      setIssuerHandler({
        cold_wallet_seed: e.cold_wallet_seed,
        DefaultIssueAmount: configurePayload.DefaultIssueAmount,
        coinSymbol: coinRef.current.props.value,
        hot_wallet_seed: e.hot_wallet_seed,
      });
    },
  });

  function onChange(e) {
    setCheck(e.target.checked);
  }

  const setIssuerHandler = async (values) => {
    try {
      setLoading(true);

      const response = await setConfigure(values);
      alert.show(response.data.payload.message);
      setLoading(false);
      onCancel_();
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setLoading(false);
        onCancel_();
      } else {
        alert.error("Adding issuer failed");
        setLoading(false);
        onCancel_();
      }
    }
  };
  const onCancel_ = () => {
    setConfigurePayload(initialConfigurePayloadState);
    onCancel();
    setCheck(false);
    formik.resetForm();
  };

  return (
    <Modal
      title="Configure Hot and Cold"
      visible={visible}
      onCancel={!loading && onCancel_}
      centered
      footer={
        <Button
          icon={<SendOutlined />}
          loading={loading}
          disabled={
            configurePayload.DefaultIssueAmount > 0 && check ? false : true
          }
          // onClick={setIssuerHandler}
          onClick={formik.handleSubmit}
          type="primary"
        >
          Set Amount
        </Button>
      }
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24}>
            <label>Issuer Seed</label>
            <Input
              id="cold_wallet_seed"
              // value={configurePayload.cold_wallet_seed}
              value={formik.values.cold_wallet_seed}
              // onChange={(e) => {
              //   setConfigurePayload({
              //     ...configurePayload,
              //     cold_wallet_seed: e.target.value,
              //     coinSymbol: coinRef.current.props.value,
              //   });
              // }}
              onChange={formik.handleChange}
            />
            <InputError>
              {formik.touched.cold_wallet_seed &&
                formik.errors.cold_wallet_seed}
            </InputError>
          </Col>
          <Col xs={24} xl={24}>
            <label>Distributor Seed</label>
            <Input
              id="hot_wallet_seed"
              // value={configurePayload.hot_wallet_seed}
              value={formik.values.hot_wallet_seed}
              // onChange={(e) => {
              //   setConfigurePayload({
              //     ...configurePayload,
              //     hot_wallet_seed: e.target.value,
              //     coinSymbol: coinRef.current.props.value,
              //   });
              // }}
              onChange={formik.handleChange}
            />
            <InputError>
              {formik.touched.hot_wallet_seed && formik.errors.hot_wallet_seed}
            </InputError>
          </Col>
          <Col xs={24} xl={24}>
            <label>Coin</label>
            <Input ref={coinRef} value={coin} />
          </Col>
          <Col xs={24} xl={24}>
            <label>Default Distribution Amount</label>
            <InputNumber
              min="0"
              value={configurePayload.DefaultIssueAmount}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                console.log(e);
                setConfigurePayload({
                  ...configurePayload,
                  DefaultIssueAmount: +e,
                  coinSymbol: coinRef.current.props.value,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={24}>
            <Checkbox checked={check} onChange={onChange}>
              Are you 100% sure about what you are doing?
            </Checkbox>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default ConfigureAssets;
