import { SendOutlined } from "@ant-design/icons";
import { Modal, Input, Row, Col, Button } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { getIssuer } from "../../../services/assetsService";
import { addHotStake, getHotStaker } from "../../../services/stakingService";
import { useDispatch, useSelector } from "react-redux";
import { gethotStakeAccountData } from "../../../store/reducers/stakingReducer";
const AddHotStakeModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const [issuerSeed, setIssuerSeed] = useState("");
  const fee = useSelector((state) => state.fee);
  const { hotstakeAccountAddress } = useSelector((state) => state.staking);
  const seedRef = useRef();
  const [loading, setLoading] = useState(false);
  const [genloading, setGenLoading] = useState(false);
  alert = useAlert();
  useEffect(() => {
    if (visible === true) {
      gethotStrakerAccount();
    }
  }, [visible]);
  const gethotStrakerAccount = async () => {
    try {
      setLoading(true);
      const data = await getHotStaker();
      dispatch(
        gethotStakeAccountData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("Fee Collector loading failed!");
      setLoading(false);
    }
  };
  const generateSeed = async () => {
    try {
      setGenLoading(true);
      const data = await getIssuer();
      setIssuerSeed(data.data.payload);
      setGenLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setGenLoading(false);
      } else {
        alert.error("Generating failed!");
        setGenLoading(false);
      }
    }
  };
  const sethotStake = async () => {
    try {
      setLoading(true);
      const response = await addHotStake({
        wallet_seed: seedRef.current.props.value,
        // coinSymbol: coinRef.current.props.value,
      });
      alert.show(response.data.payload);
      setLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setLoading(false);
      } else {
        alert.error("Adding Cold Stake failed");
        setLoading(false);
      }
    }
  };
  const onCancelHandler = () => {
    onCancel();
    setIssuerSeed("");
  };
  return (
    <Modal
      //   key={key}
      title="Add Hot Stake"
      visible={visible}
      onCancel={!loading && onCancelHandler}
      footer={
        hotstakeAccountAddress && hotstakeAccountAddress ? (
          <Button disabled={true} type="primary">
            Add Hot Stake
          </Button>
        ) : (
          <Button
            //   icon={<SendOutlined />}
            loading={loading}
            onClick={sethotStake}
            type="primary"
          >
            Add Hot Stake
          </Button>
        )
      }
      centered
    >
      <div>
        <Row gutter={[16, 16]}>
          {hotstakeAccountAddress && hotstakeAccountAddress ? (
            <Col lg={24}>
              <Input value={hotstakeAccountAddress} />
            </Col>
          ) : (
            <>
              <Col lg={24}>
                <Input ref={seedRef} value={issuerSeed} />
              </Col>
              <Col lg={24}>
                <Button loading={genloading} onClick={generateSeed}>
                  Generate Hot Stake
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
    </Modal>
  );
};
export default AddHotStakeModal;
