import React from "react";
import { Layout } from "antd";
import styles from "./Header.module.scss";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

const Header = ({ collapsed, toggle }) => {
  const { Header } = Layout;

  return (
    <>
      <Header className={styles.headerWrapper}>
        {collapsed ? (
          <MenuUnfoldOutlined className={styles.trigger} onClick={toggle} />
        ) : (
          <MenuFoldOutlined className={styles.trigger} onClick={toggle} />
        )}
      </Header>
    </>
  );
};

export default Header;
