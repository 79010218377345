import {
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Modal,
  Input,
  Row,
  Typography,
  Button,
  Divider,
  InputNumber,
} from "antd";
import React, { useRef, useState } from "react";
import { SendOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { setIssueAssets } from "../../../services/assetsService";
import { useAlert } from "react-alert";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../InputError/InputError";

const initialIssueDataState = {
  Amount: 0,
  cold_wallet_seed: "",
  coinSymbol: "",
  distributorAddress: "",
};

const validationSchema = Yup.object({
  cold_wallet_seed: Yup.string()
    .required("Secret key is required")
    .min(29, "The length of Secret key must be 29")
    .max(29, "The length of Secret key must be 29"),
  // Amount: Yup.number()
  //   .required("Secret key is required")
  //   .min(1, "The minimum value must be 1"),
});

const IssueAssetModal = ({ detail, visible, onCancel, distributer, coin }) => {
  const [issueData, setIssueData] = useState(initialIssueDataState);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const alert = useAlert();
  const distributorRef = useRef();
  const coinRef = useRef();
  const formik = useFormik({
    initialValues: initialIssueDataState,
    validationSchema,
    onSubmit: (e) => {
      // console.log();
      setIssueAmountHandler({
        cold_wallet_seed: e.cold_wallet_seed,
        Amount: issueData.Amount,
        coinSymbol: coinRef.current.props.value,
        distributorAddress: distributorRef.current.props.value,
      });
    },
  });

  // const isserSeedHandler = (e) => {
  //   setIssueData({
  //     ...issueData,
  //     cold_wallet_seed: e.target.value,
  //     coinSymbol: coinRef.current.props.value,
  //     distributorAddress: distributorRef.current.props.value,
  //   });
  // };
  const amountHandler = (e) => {
    setIssueData({
      ...issueData,
      Amount: +e,
      coinSymbol: coinRef.current.props.value,
      distributorAddress: distributorRef.current.props.value,
    });
  };

  function onChange(e) {
    setCheck(e.target.checked);
  }

  const onCancel_ = () => {
    setIssueData(initialIssueDataState);
    onCancel();
    setCheck(false);
    formik.resetForm();
  };

  const setIssueAmountHandler = async (values) => {
    try {
      setLoading(true);
      const response = await setIssueAssets(values);
      setLoading(false);
      onCancel_();
      alert.success(response.data.message && response.data.message);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message && err.response.data.message);
        setLoading(false);
        onCancel_();
      } else {
        alert.error("Asset issue failed");
        setLoading(false);
        onCancel_();
      }
    }
  };
  const { TextArea } = Input;
  return (
    <Modal
      title="Issue Assets"
      visible={visible}
      onCancel={!loading && onCancel_}
      footer={
        <Button
          loading={loading}
          // onClick={setIssueAmountHandler}
          onClick={formik.handleSubmit}
          type="primary"
          icon={<SendOutlined />}
          disabled={
            // issueData.cold_wallet_seed.length &&
            issueData.Amount > 0 && check === true ? false : true
          }
        >
          Issue BTC Assets
        </Button>
      }
      centered
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={24}>
            <h1 style={{ color: "red" }}>Warning!</h1>
          </Col>

          <Col xs={24} lg={24}>
            <div style={{ opacity: "70%" }}>
              Assets are real funds that have worth. In order to issue BTC
              assets please make sure you have issuer public key available.
              <Divider />
              Make sure you are only issuing the required amount that need as
              running balance. Only issue assets if you are pretty sure about
              this scenario. Make sure no one is looking at your system.
              <Divider />
            </div>
          </Col>
          <Col xs={24} lg={24}>
            <label>Distributor</label>
            <Input ref={distributorRef} value={distributer} />
          </Col>
          <Col xs={24} lg={24}>
            <label>Enter Asset Issuer Secret Key</label>
            <Input
              id="cold_wallet_seed"
              // value={issueData.cold_wallet_seed}
              value={formik.values.cold_wallet_seed}
              // onChange={isserSeedHandler}
              onChange={formik.handleChange}
            />
            <InputError>
              {formik.touched.cold_wallet_seed &&
                formik.errors.cold_wallet_seed}
            </InputError>
          </Col>
          <Col xs={24} lg={24}>
            <label> Coin</label>

            <Input ref={coinRef} value={coin} />
          </Col>
          <Col xs={24} lg={24}>
            <label> Enter Amount to issue</label>

            <InputNumber
              id="Amount"
              min="0"
              value={issueData.Amount}
              // value={formik.values.Amount}
              style={{ width: "100%" }}
              type="number"
              onChange={amountHandler}
              // onChange={formik.handleChange}
            />
            {/* <InputError>
              {formik.touched.Amount && formik.errors.Amount}
            </InputError> */}
          </Col>

          <Col xs={24} lg={24}>
            <Checkbox checked={check} onChange={onChange}>
              Are you 100% sure about what you are doing?
            </Checkbox>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default IssueAssetModal;
