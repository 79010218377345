import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import styles from "./LoadingIcon.module.scss";

function LoadingIcon() {
  return (
    <div className={styles.loading}>
      <Spin />
    </div>
  );
}

export default LoadingIcon;
