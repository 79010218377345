import { SendOutlined, SwapOutlined } from "@ant-design/icons";
import { Modal, Input, Row, Col, Button, Select } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { getIssuer } from "../../../services/assetsService";
import {
  setSwapAccount,
  updateSwapAccount,
} from "../../../services/swapaccountService";
import { useDispatch, useSelector } from "react-redux";
const SwapAccountModal = ({
  detail,
  visible,
  onCancel,
  coin,
  key,
  postOrPatch,
}) => {
  const dispatch = useDispatch();
  const [issuerSeed, setIssuerSeed] = useState("");
  const fee = useSelector((state) => state.fee);
  //   const coinRef = useRef();
  const seedRef = useRef();
  const [loading, setLoading] = useState(false);
  const [genloading, setGenLoading] = useState(false);
  const coins = useSelector((state) => state.assets);
  alert = useAlert();
  const generateSeed = async () => {
    try {
      setGenLoading(true);
      const data = await getIssuer();
      setIssuerSeed(data.data.payload);
      setGenLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setGenLoading(false);
      } else {
        alert.error("Generating failed!");
        setGenLoading(false);
      }
    }
  };
  const setSwapaccountHandler = async () => {
    try {
      setLoading(true);
      const response = postOrPatch
        ? await updateSwapAccount({
            wallet_seed: seedRef.current.props.value,
          })
        : await setSwapAccount({
            wallet_seed: seedRef.current.props.value,
          });
      alert.show(response.data.payload);
      setLoading(false);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message);
        setLoading(false);
      } else {
        alert.error("Account swapping failed");
        setLoading(false);
      }
    }
  };
  const onCancelHandler = () => {
    onCancel();
    setIssuerSeed("");
  };

  // const feeCollectorSeedHandler = (e) => {
  //   setIssuerSeed(e.target.value);
  // };
  return (
    <Modal
      title="Swap Account"
      visible={visible}
      onCancel={!loading && onCancelHandler}
      footer={
        <Button
          // icon={<SwapOutlined />}
          loading={loading}
          onClick={setSwapaccountHandler}
          type="primary"
        >
          {postOrPatch ? "Update Swap Account" : " Add Swap Account"}
        </Button>
      }
      centered
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Input
              ref={seedRef}
              value={issuerSeed}
              // onChange={feeCollectorSeedHandler}
              // placeholder="Enter Issuer or generate issuer"
            />
          </Col>
          <Col lg={24}>
            <Button loading={genloading} onClick={generateSeed}>
              Generate Seed
            </Button>
          </Col>
          {/* <Col lg={24}>
            <Select
              value={createSettingPayload.coinShort}
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,

                  coinShort: e,
                });
              }}
              style={{ width: "100%" }}
            >
              {coins.data.map((element) => (
                <Select.Option key={element.key} value={element.ticker}>
                  {element.ticker}
                </Select.Option>
              ))}
            </Select>
          </Col> */}
        </Row>
      </div>
    </Modal>
  );
};
export default SwapAccountModal;
