import React, { useState } from "react";
import GenericModal from "../../_common/genericModal/index";
import styles from "./Notifications.module.scss";
import { ThreeDots } from "react-loader-spinner";
import { useAlert } from "react-alert";
import { sendNotification } from "../../../services/admin.service";
import { notificationSchema } from "./Notification.schema";
import { Formik, Form, Field, ErrorMessage } from "formik";
const NotificationModal = ({ isOpen, toggle }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    title: "",
    body: "",
  };
  const handleAllNotifications = async (values) => {
    try {
      setLoading(true);
      const send = await sendNotification({
        ...values,
        title: String(values.title),
        body: String(values.body),
      });
      toggle();
      setLoading(false);
      alert.show("Sent Notifications", { type: "success" });
    } catch (error) {
      alert.show("Unable to send notifications to users,Please Try Again", {
        type: "error",
      });
      setLoading(false);
    }
  };
  return (
    <>
      <GenericModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainheading}>Send Notification</div>
        <Formik
          initialValues={initialValues}
          validationSchema={notificationSchema}
          onSubmit={handleAllNotifications}
        >
          <Form className={styles.form}>
            <div className={styles.inputDiv}>
              <Field type="text" placeholder="Title" name="title" />
            </div>
            <ErrorMessage name="title">
              {(errorText) => <div className={styles.error}>{errorText}</div>}
            </ErrorMessage>

            <Field
              type="text"
              placeholder="Place message here"
              name="body"
              as="textarea"
              className={styles.textArea}
            />

            <ErrorMessage name="body">
              {(errorText) => <div className={styles.error}>{errorText}</div>}
            </ErrorMessage>

            <button className={styles.button} disabled={loading}>
              {loading ? (
                <ThreeDots
                  height="30"
                  width="30"
                  color="white"
                  ariaLabel="loading"
                  marginBottom="10px"
                />
              ) : (
                "Send"
              )}
            </button>
          </Form>
        </Formik>
      </GenericModal>
    </>
  );
};

export default NotificationModal;
