import { HTTP_CLIENT } from "../utills/request";
import { URL } from "../utills/constants/url.constants";

export const banUser = (data) => {
  return HTTP_CLIENT.post(URL.BAN_USER, data);
};
export const unBanUser = (data) => {
  return HTTP_CLIENT.post(URL.UNBAN_USER, data);
};
export const createNewTier = (data) => {
  return HTTP_CLIENT.post(URL.CREATE_NEW_TIER, data);
};
export const deleteTier = (id) => {
  return HTTP_CLIENT.delete(`${URL.DELETE_TIER}/${id}`);
};
export const editTier = (id, data) => {
  return HTTP_CLIENT.patch(`${URL.EDIT_TIER}/${id}`, data);
};
export const createNewGym = (data) => {
  return HTTP_CLIENT.post(URL.CREATE_NEW_GYM, data);
};
export const deleteGym = (id) => {
  return HTTP_CLIENT.delete(`${URL.DELETE_GYM}/${id}`);
};
export const editGym = (id, data) => {
  return HTTP_CLIENT.patch(`${URL.EDIT_GYM}/${id}`, data);
};
export const createNewSession = (data) => {
  return HTTP_CLIENT.post(URL.CREATE_NEW_SESSION, data);
};
export const editNewSession = (id, data) => {
  return HTTP_CLIENT.patch(`${URL.EDIT_SESSION}/${id}`, data);
};

export const deleteSession = (id) => {
  return HTTP_CLIENT.delete(`${URL.DELETE_SESSION}/${id}`);
};
export const createNewReferral = (data) => {
  return HTTP_CLIENT.post(URL.CREATE_NEW_REFERRAL, data);
};
export const editNewReferral = (id, data) => {
  return HTTP_CLIENT.patch(`${URL.EDIT_REFERRAL}/${id}`, data);
};

export const deleteReferral = (id) => {
  return HTTP_CLIENT.delete(`${URL.DELETE_REFERRAL}/${id}`);
};
export const sendNotification = (data) => {
  return HTTP_CLIENT.post(URL.SEND_NOTIFICATION_TO_ALL, data);
};
