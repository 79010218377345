import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  count: 0,
};

export const StatsReducer = createSlice({
  name: "stats",
  initialState,
  reducers: {
    getStatsData(state, action) {
      state.data = action.payload.data.payload;
    },
  },
});

export const { getStatsData } = StatsReducer.actions;

export default StatsReducer.reducer;
