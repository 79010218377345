import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
  swapaccountdata: "",
};

export const SwapReducer = createSlice({
  name: "swap",
  initialState,
  reducers: {
    getSwapAccountData(state, action) {
      state.swapaccountdata = action.payload.data.data.payload.address;
    },
  },
});

export const { getSwapAccountData } = SwapReducer.actions;

export default SwapReducer.reducer;
