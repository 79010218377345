import { EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";

function LoadingButton(props) {
  const [onClickLoading, setOnClickLoading] = useState(false);

  const loadingHandler = () => {
    setOnClickLoading(false);
    console.log(onClickLoading);
  };

  return (
    <Button
      loading={onClickLoading}
      onClick={() => {
        // console.log(account);
        props.viewKYCModelHangler(props.account, loadingHandler);
        setOnClickLoading(true);
      }}
      icon={<EyeOutlined />}
    ></Button>
  );
}

export default LoadingButton;
