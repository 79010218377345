import { URL } from "../utills/constants/url.constants";
import HTTP_CLIENT from "../utills/request";
export const setSwapAccount = (data) => {
  return HTTP_CLIENT.post(URL.SET_SWAP_ACCOUNT, data);
};
export const getSwapAcountDetail = () => {
  return HTTP_CLIENT.get(URL.GET_SWAP_DEATIL);
};
export const deleteSwapAccount = () => {
  return HTTP_CLIENT.post(URL.DELETE_SWAP_ACCOUNT);
};
export const configureSwapaccount = () => {
  return HTTP_CLIENT.post(URL.CONFIGURE_SWAP_ACCOUNT);
};
export const IssueAssetsSwapAccount = () => {
  return HTTP_CLIENT.post(URL.ISSUE_ASSETS_SWAP_ACCOUNT);
};
export const updateSwapAccount = (data) => {
  return HTTP_CLIENT.post(URL.UPDATE_SWAP_ACCOUNT, data);
};
