import { Checkbox, Col, Modal, Input, Row, Button, Form } from "antd";
import React, { useRef, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { setTrust } from "../../../services/assetsService";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputError from "../InputError/InputError";

const intitialTrustDataState = {
  cold_wallet_address: "",
  user_wallet_seed: "",
  coinSymbol: "",
};

const validationSchema = Yup.object({
  user_wallet_seed: Yup.string()
    .required("Secret key is required")
    .min(29, "The length of Secret key must be 29")
    .max(29, "The length of Secret key must be 29"),
});

const AddIssuerModal = ({ detail, visible, onCancel, coin, passIssure }) => {
  const coinRef = useRef();
  const IssueRef = useRef();
  const [loading, setLoading] = useState(false);
  // const [trustData, setTrustData] = useState(intitialTrustDataState);
  const [check, setCheck] = useState(false);
  const formik = useFormik({
    initialValues: intitialTrustDataState,
    validationSchema,
    onSubmit: (values) => {
      setTrustHandler({
        cold_wallet_address: IssueRef.current.props.value,
        user_wallet_seed: values.user_wallet_seed,
        coinSymbol: coinRef.current.props.value,
      });
    },
  });

  function onChange(e) {
    setCheck(e.target.checked);
  }

  // const seedOnChangeHandler = (e) => {
  //   setTrustData({
  //     cold_wallet_address: IssueRef.current.props.value,
  //     user_wallet_seed: e.target.value,
  //     coinSymbol: coinRef.current.props.value,
  //   });
  // };

  const onCancel_ = () => {
    onCancel();
    // setTrustData(intitialTrustDataState);
    setCheck(false);
    formik.resetForm();
  };

  const setTrustHandler = async (input) => {
    try {
      setLoading(true);
      const response = await setTrust(input);
      setLoading(false);
      onCancel_();
      alert.success(response.data.message && response.data.message);
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message && err.response.data.message);
        setLoading(false);
        onCancel_();
      } else {
        alert.error("Trust failed");
        setLoading(false);
        onCancel_();
      }
    }
  };
  return (
    <Modal
      title="Trust to issuer"
      visible={visible}
      onCancel={!loading && onCancel_}
      footer={
        <Button
          disabled={check ? false : true}
          loading={loading}
          // onClick={setTrustHandler}
          onClick={formik.handleSubmit}
          type="primary"
          icon={<SendOutlined />}
        >
          Add Trust
        </Button>
      }
      centered
    >
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <label>Issuer</label>
          <Input ref={IssueRef} value={passIssure} placeholder="" />
        </Col>
        <Col lg={24}>
          <label>Coin</label>
          <Input ref={coinRef} value={coin} placeholder="" />
        </Col>
        <Col lg={24}>
          <label>Secret key</label>
          <Input
            id="user_wallet_seed"
            // value={trustData.user_wallet_seed}
            value={formik.values.user_wallet_seed}
            // onChange={seedOnChangeHandler}
            onChange={formik.handleChange}
            placeholder="Secret key of source account who want to trust"
          />

          <InputError>
            {formik.touched.user_wallet_seed && formik.errors.user_wallet_seed}
          </InputError>
        </Col>

        <Col lg={24}>
          <Checkbox checked={check} onChange={onChange}>
            Are you sure to trust?
          </Checkbox>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddIssuerModal;
