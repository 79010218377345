import React, {useState} from "react";
import { Table, Spin, Menu } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DownOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import styles from "./User.module.scss";
import { useAlert } from "react-alert";
import { Row, Col, Dropdown, Button, Input } from "antd";

const Users = () => {
  const alert = useAlert();
  const loading = false;

  const usersData = [
    {
      name: "Muhammad waqas",
      role: "Management",
      publickey:
        "HFJDHFKSDJHSCJNK5KSJDHF7WI7WKERJFH8REF487FERIUF784W8EDJNSKLXCNE",
    },
    {
      name: "Muhammad waqas",
      role: "Management",
      publickey:
        "HFJDHFKSDJHSCJNK5KSJDHF7WI7WKERJFH8REF487FERIUF784W8EDJNSKLXCNE",
    },
    {
      name: "Muhammad waqas",
      role: "Management",
      publickey:
        "HFJDHFKSDJHSCJNK5KSJDHF7WI7WKERJFH8REF487FERIUF784W8EDJNSKLXCNE",
    },
    {
      name: "Muhammad waqas",
      role: "Management",
      publickey:
        "HFJDHFKSDJHSCJNK5KSJDHF7WI7WKERJFH8REF487FERIUF784W8EDJNSKLXCNE",
    },
    {
      name: "Muhammad waqas",
      role: "Management",
      publickey:
        "HFJDHFKSDJHSCJNK5KSJDHF7WI7WKERJFH8REF487FERIUF784W8EDJNSKLXCNE",
    },
  ];
  // pagination //
  const columns = [
    {
      title: "",
      key: "",
      dataIndex: "",
      render: () => (
        <Button
          style={{
            cursor: "default",
          }}
          icon={<UserOutlined />}
          type="primary"
          shape="circle"
          size={1}
        ></Button>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "PublicKey",
      dataIndex: "publickey",
      key: "publickey",
    },
  ];
  const menu = (
    <Menu>
      <Menu.Item key="1">1st menu item</Menu.Item>
      <Menu.Item key="2">2nd menu item</Menu.Item>
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={antIcon} />,
  };
  const getFullDate = (date) => {
    const dateAndTime = date.split("T");
    return dateAndTime[0].split("-").reverse().join("-");
  };
  const handleSearch = (e)=> {
    console.log(e.target.value)
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Admin Users</div>

          <div className={styles.divider} />
          {/* <div>
            You can view the Admin Users detail on this page and edit them
          </div> */}
        </div>
        <div className={styles.midsection}>
          <Row>
            {/* <Col lg={8}>
            
            </Col> */}
            <Col lg={8}>
              <Dropdown overlay={menu}>
                <Button>
                  BTC List <DownOutlined size="small" />
                </Button>
              </Dropdown>
            </Col>
            <Col lg={8} sm={24}>
              <Input prefix={<SearchOutlined />} onChange={handleSearch} />
            </Col>
          </Row>
        </div>
        <Table
          loading={loading ? tableLoading : false}
          columns={columns}
          // pagination={(state.pageCount, state.page)}
          scroll={{ x: true }}
          dataSource={usersData}
        />
         
      </div>
    </>
  );
};

export default Users;
