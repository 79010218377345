import { CopyOutlined, SendOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getAssets } from "../../../services/assetsService";
import {
  setCreateSetting,
  setUpdateSetting,
} from "../../../services/fee.service";
import { getAssetsData } from "../../../store/reducers/assestsReducer";

function CreateSetting({
  detail,
  visible,
  onCancel,
  Distributer,
  edit,
  createSettingPayload,
  setCreateSettingPayload,
  postOrPatch,
  changeReloadStateHandler,
}) {
  const coinRef = useRef();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // <label>Level Id</label>;

  const [check, setCheck] = useState(false);
  const alert = useAlert();

  function onChange(e) {
    setCheck(e.target.checked);
  }

  const coins = useSelector((state) => state.assets);

  const getAssetsDataforCoins = async () => {
    try {
      setLoading(true);
      const data = await getAssets();
      dispatch(
        getAssetsData({
          data: data,
        })
      );
      setLoading(false);
    } catch (error) {
      alert.error("Assets loading failed!");
      setLoading(false);
    }
  };

  useEffect(() => {
    coins.data.length < 1 && getAssetsDataforCoins();
  }, []);

  const setCreateSettingHandler = async () => {
    try {
      setLoading(true);
      console.log(createSettingPayload);
      const response = postOrPatch
        ? await setUpdateSetting(createSettingPayload)
        : await setCreateSetting(createSettingPayload);
      alert.success(response.data.message && response.data.message);
      setLoading(false);
      onCancel_();
      changeReloadStateHandler();
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data.message && err.response.data.message);
        setLoading(false);
        onCancel_();
      } else {
        setLoading(false);
        onCancel_();
        alert.error("Failed");
      }
    }
  };

  const onCancel_ = () => {
    onCancel();
    setCreateSettingPayload({
      levelId: 1, //number
      coinShort: "", //string
      isHybridAsset: false, //boolean
      depositFeeFixed: 0, //number
      depositFeePercent: 0, //number
      depositMinAmount: 0, //number
      depositMaxAmount: 0, //number
      withdrawFeeFixed: 0, //number
      withdrawFeePercent: 0, //number
      withdrawMinAmount: 0, //number
      withdrawMaxAmount: 0, //number
    });
    setCheck(false);
  };

  return (
    <Modal
      title="Create Settings"
      visible={visible}
      onCancel={() => {
        !loading && onCancel_();
      }}
      centered
      width={1000}
      footer={
        <Button
          icon={<SendOutlined />}
          loading={loading}
          disabled={check && createSettingPayload.coinShort ? false : true}
          onClick={setCreateSettingHandler}
          type="primary"
        >
          Confirm Settings
        </Button>
      }
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12} xl={12}>
            <label>Coin</label>
            {postOrPatch ? (
              <Input ref={coinRef} value={createSettingPayload.coinShort} />
            ) : (
              <Select
                value={createSettingPayload.coinShort}
                onChange={(e) => {
                  setCreateSettingPayload({
                    ...createSettingPayload,

                    coinShort: e,
                  });
                }}
                style={{ width: "100%" }}
              >
                {coins.data.map((element) => (
                  <Select.Option key={element.key} value={element.ticker}>
                    {element.ticker}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Level Id</label>
            <InputNumber
              min="1"
              max="3"
              style={{ width: "100%" }}
              type="number"
              value={createSettingPayload.levelId}
              onChange={(e) => {
                console.log(e);
                setCreateSettingPayload({
                  ...createSettingPayload,
                  levelId: +e,
                });
              }}
            />
          </Col>

          <Col xs={24} lg={24} xl={24}>
            <Checkbox
              checked={createSettingPayload.isHybridAsset}
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  isHybridAsset: e.target.checked,
                });
              }}
            >
              Is Hybrid Asset?
            </Checkbox>
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Deposit Fee Fixed</label>
            <InputNumber
              min="0"
              step="0.0001"
              value={createSettingPayload.depositFeeFixed}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  depositFeeFixed: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Deposit Fee Percent</label>
            <InputNumber
              min="0"
              step="0.0001"
              value={createSettingPayload.depositFeePercent}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  depositFeePercent: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Deposit Min Amount</label>
            <InputNumber
              min="0"
              step="0.0001"
              value={createSettingPayload.depositMinAmount}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  depositMinAmount: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Deposit Max Amount</label>
            <InputNumber
              min="0"
              step="0.001"
              value={createSettingPayload.depositMaxAmount}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  depositMaxAmount: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Withdraw Fee Fixed</label>
            <InputNumber
              min="0"
              step="0.001"
              value={createSettingPayload.withdrawFeeFixed}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  withdrawFeeFixed: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Withdraw Fee Percent</label>
            <InputNumber
              min="0"
              step="0.0001"
              value={createSettingPayload.withdrawFeePercent}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  withdrawFeePercent: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Withdraw Min Amount</label>
            <InputNumber
              min="0"
              step="0.0001"
              value={createSettingPayload.withdrawMinAmount}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  withdrawMinAmount: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <label>Withdraw Max Amount</label>
            <InputNumber
              min="0"
              step="0.01"
              value={createSettingPayload.withdrawMaxAmount}
              style={{ width: "100%" }}
              type="number"
              onChange={(e) => {
                setCreateSettingPayload({
                  ...createSettingPayload,
                  withdrawMaxAmount: +e,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={24}>
            <Checkbox checked={check} onChange={onChange}>
              Are you 100% sure about what you are doing?
            </Checkbox>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default CreateSetting;
