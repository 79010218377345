import React, { useState } from "react";
import styles from "./login.module.scss";
import logo from "../../assets/logo.svg";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import { setIsLoggedIn } from "../../store/reducers/authReducers";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../services/auth.service";
import { verifyEmailCode } from "../../services/user.service";
import { Navigate, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { validationSchema } from "./login.schema";
import { Button, Checkbox, Col, Divider, Input, Row } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import InputError from "../_common/InputError/InputError";

const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const data = await login(values);

      console.log("data", data.data.payload.jwt);
      if (data.data.statusCode === 200) {
        dispatch(
          setIsLoggedIn({
            isLoggedIn: true,
            data: data.data.payload.jwt,
          })
        );
        <Navigate to="/dashboard" />;
        alert.success(data.data.message);
      }
      //  else {
      //   alert.error(data.data.message);
      //   <Navigate to="/" />;
      // }
      setLoading(false);
    } catch (error) {
      alert.error("Login Failed!");
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  // send pin to email//
  // const handleEmail = (values) => {
  //   setLoading(true);
  //   login(values)
  //     .then((response) => {
  //       if (response && response.status === 201) {
  //         setActiveEmailInput(false);
  //         setActivePinInput(true);
  //         setIsEmail(values.email);
  //         alert.show("The pin code has been sent on email", {
  //           type: "success",
  //         });
  //       } else {
  //         setLoading(false);
  //         alert.show("Emial is not valid", { type: "error" });
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       alert.show("Un Authorized Email", { type: "error" });
  //     });
  // };
  // // LoggingIn//
  // const handleLogin = async (values) => {
  //   try {
  //     setLoad(true);
  //     const response = await verifyEmailCode({
  //       verificationCode: values.verificationCode,
  //       email: isEmail,
  //     });
  //     dispatch(
  //       setIsLoggedIn({
  //         isLoggedIn: true,
  //         accessToken: response.data.accessToken,
  //       })
  //     );
  //     alert.show("Successfully Logged In", { type: "success" });
  //   } catch (error) {
  //     setLoad(false);
  //     alert.show("Invalid Verification code", { type: "error" });
  //   }
  // };
  // redirect to dashboard//
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.loginForm}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>

          <Divider />
          <div className={styles.formWrapper}>
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={24}>
                <label>Email</label>
                <Input
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <InputError>
                  {formik.touched.email && formik.errors.email}
                </InputError>
              </Col>
              <Col xs={24} xl={24}>
                <label>Password</label>
                <Input.Password
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <InputError>
                  {formik.touched.password && formik.errors.password}
                </InputError>
              </Col>
              <Col xs={24} xl={24}>
                <Checkbox>Remember me</Checkbox>
              </Col>
              <Col xs={24} xl={24}>
                <Button
                  block
                  icon={<LoginOutlined />}
                  type="primary"
                  htmlType="submit"
                  onClick={formik.handleSubmit}
                >
                  Login
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
